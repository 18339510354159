<template>
  <div>
    <v-card>
      <v-card-title>
        {{ name }}
      </v-card-title>
      <v-card-text>
        <!-- <h2>
          Mettre les informations du certificat d'etalonnage selon ISO/IEC 17025
        </h2> -->
        <h2>Calibration certificate information</h2>
        <table></table>
      </v-card-text>
      <!-- <v-card-actions>Telecharger - Imprimer</v-card-actions> -->
    </v-card>
  </div>
</template>

<script>
export default {
  setup() {
    const name = "Cerificate";

    return {
      name,
    };
  },
};
</script>

<style></style>
