<template>
  <!-- action -->
  <v-dialog v-model="dialog" scrollable max-width="500px">
    <template v-slot:activator="{ props, isActive }">
      <!-- <v-btn color="primary" v-bind="props"> Open Dialog </v-btn> -->
      <v-icon small class="me-2" v-bind="props" @click="editItem(alert)">
        mdi-pencil-outline
      </v-icon>
    </template>
    <v-card class="mx-auto my-12" min-width="800">
      <v-card-title class="text-h5">Update alert</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-template>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.name"
                label="Alert Name"
                outlined
                dense.multi-col-validation
                placeholder="Alert Name"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.organizationName"
                label="Org Name"
                outlined
                dense
                placeholder="Org Name"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="dialog.value = false">
          Cancel
        </v-btn>
        <v-btn color="success" @click="updateUser(editedItem)"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- Delete alert -->

  <!-- </template> -->
</template>

<script>
export default {
  setup(props) {
    let dialog = ref(false);
    let editedIndex = ref([]);
    let editedItem = ref();

    const editItem = (item) => {
      //console.log("Item" + item.name);
      editedItem.value = item;
      dialog.value = true;
    };
    const updateUser = (item) => {};
    return {
      updateUser,
      editItem,
      dialog,
      editedItem,
      editedIndex,
    };
  },
};
</script>

<style></style>
