<template>
  <v-form class="multi-col-validation">
    <h1>Create Organization</h1>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="organizationName"
          label="Name"
          outlined
          dense
          placeholder="Name"
          hide-details
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="organizationDescription"
          filled
          auto-grow
          label="Four rows"
          rows="4"
          row-height="30"
          shaped
          required
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" @click="submit"> Submit </v-btn>
        <v-btn type="reset" outlined class="mx-2"> Reset </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
export default {
  setup(props) {
    const organizationName = ref("");
    const organizationDescription = ref("");
    const store = useStore();

    const submit = async () => {
      //form.value.resetValidation();
      console.log(organizationName.value);
      console.log(organizationDescription.value);
      await store.dispatch("organizationStore/createOrganization", {
        name: organizationName.value,
        desc: organizationDescription.value,
      });
    };

    return {
      organizationName,
      organizationDescription,
      submit,
    };
  },
};
</script>

<style></style>
