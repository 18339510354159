<template>
  <v-container>
    <h3>{{ $t("users") }}</h3>
    <div>
      <!-- <v-btn class="ma-2" outlined color="indigo"> Outlined Button </v-btn> -->
      <router-link to="/createuser"
        ><v-btn class="ma-2" outlined color="indigo">
          {{ $t("create") }}
        </v-btn></router-link
      >
    </div>
    <v-container>
      <v-table fixed-header height="300px">
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Calories</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in notificationGroups" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.calories }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-container>
  </v-container>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { onBeforeMount } from "vue";
export default {
  name: "Notifications_Name",
  setup() {
    let search = ref();
    let notificationGroups = ref([]);
    const headers = ["Id", "Name", "Role", "Création", "Modifier"];
    const store = useStore();

    onBeforeMount(async () => {
      //console.log("Users: before mount");
      store.dispatch("getAllUsers");
    });

    return { headers, notificationGroups, search };
  },
};
</script>

<style></style>
