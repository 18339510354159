export default {
  setUser(state, payload) {
    state.user = payload;
    state.userId = payload.userId;
    state.token = payload.token;
    state.organizations = payload.userOrg;
    state.userEmail = payload.userEmail;
  },

  setUserId(state, payload) {
    state.userId = payload;
  },

  setToken(state, payload) {
    state.token = payload;
  },

  setSensors(state, payload) {
    state.sensors = payload;
  },

  setSensorsInfo(state, payload) {
    state.sensorsInfo = payload;
  },

  setSoftVersion(state, payload) {
    state.version = payload;
  },

  toggleDrawer(state) {
    state.drawer = !state.drawer;
  },
};
