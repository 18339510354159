<template>
  <!-- <div>{{ datasList }}</div> -->
  <div class="d-flex flex-row-reverse mb-6">
    <v-btn
      class="ma-2 pa-2"
      color="success"
      variant="flat"
      @click="isCreate = true"
    >
      Create
    </v-btn>
    <v-btn
      class="ma-2 pa-2"
      color="success"
      variant="flat"
      @click="updatePageList()"
    >
      &nbsp;&nbsp;&nbsp; List &nbsp;&nbsp;&nbsp;
    </v-btn>
  </div>

  <v-container fluid>
    <div>
      <notification-create v-if="isCreate" />
    </div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      v-if="isCreate == false"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Notifications</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ props }">
              <v-btn dark class="mb-2" v-bind="props"> New Item </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Dessert name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue-darken-1"
                  variant="text"
                  @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item.raw)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item.raw)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import NotificationCreate from "./NotificationCreate.vue";

export default {
  components: { NotificationCreate },
  setup(props) {
    let items = [];
    let isCreate = ref(false);
    const dialog = ref(false);
    const dialogDelete = ref(false);

    const headers = [
      //{ title: "ID", key: "_id" },
      { title: "NAME", key: "name" },
      { title: "CREATED", key: "createdAt" },
      { title: "UPDATE", key: "updatedAt" },
      { title: "ACTIONS", key: "actions" },
    ];

    let editedIndex = -1;
    let itemIdTobeDelelete = 0;
    let editedItem = ref({ name: "", users: [] });

    const editItem = (item) => {
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    const deleteItem = (item) => {
      dialogDelete.value = true;
      editedItem.value = Object.assign({}, item);
    };

    const closeDelete = () => {
      dialogDelete.value = false;
      editedIndex = -1;
    };

    const deleteItemConfirm = async () => {
      console.log("Item " + itemIdTobeDelelete);
      itemIdTobeDelelete = 0;
      store.dispatch(
        "sensorsStore/deleteNotificationList",
        editedItem.value._id
      );
      closeDelete();
      await getAll();
    };

    const close = () => {
      editedIndex = -1;
      dialog.value = false;
    };

    const save = () => {
      close();
    };

    const formTitle = computed(() => {
      return editedIndex === -1 ? "New Item" : "Edit Item";
    });

    onMounted(() => {
      getAll();
    });

    onBeforeMount(() => {});

    ////////////////////////////////////////////////////////////////////////
    let datasList = ref([]);
    let datasListRest = ref([]);

    const store = useStore();

    const isGetAll = ref(false);
    const getAll = async () => {
      isGetAll.value = false;
      await store.dispatch("sensorsStore/getAllNotificationList");
    };

    items = computed(() => {
      return store.getters["sensorsStore/getNotificationList"];
    });

    const updatePageList = () => {
      isGetAll.value = false;
      isCreate.value = false;
      getAll();
    };

    return {
      dialog,
      dialogDelete,
      headers,
      editedItem,
      editItem,
      close,
      save,
      deleteItemConfirm,
      deleteItem,
      closeDelete,
      updatePageList,
      formTitle,
      datasList,
      datasListRest,
      items,
      isCreate,
    };
  },
};
</script>
