<template>
  <v-row>
    <!-- <v-col cols="12" md="6">
      <div class="d-flex flex-row mb-6 bg-surface-variant">
        <h3>Notifications</h3>
      </div> 
    </v-col> -->
    <v-col cols="12">
      <div class="d-flex flex-row-reverse mb-6">
        <v-btn class="ma-2 pa-2" color="success" variant="flat">
          Createteee
        </v-btn>
        <v-btn class="ma-2 pa-2" color="success" variant="flat">
          &nbsp;&nbsp;&nbsp; List &nbsp;&nbsp;&nbsp;
        </v-btn>
      </div>
    </v-col>
  </v-row>

  <v-container fluid>
    <v-form>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="formData.name"
            clearable
            label="Nom"
            type="text"
            variant="outlined"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            :items="usersGroup"
            v-model="formData.userGroup"
            label="Users group"
            variant="outlined"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3">
          <v-select
            :items="['notificationType']"
            v-model="formData.notificationType"
            label="Type"
            variant="outlined"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            v-model="formData.userName"
            :items="['users']"
            label="About type"
            variant="outlined"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :items="['Email', 'Sms', 'Phone']"
            v-model="formData.emailSmsPhone"
            label="Email-Sms-Phone"
            variant="outlined"
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            label="Delay"
            v-model="formData.delay"
            type="number"
            variant="outlined"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color="success" @click="submit"> Submit </v-btn>
          <v-btn color="error" type="reset" outlined class="mx-2">
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { reactive, ref } from "vue";

export default {
  setup() {
    const usersGroup = ["toto", "Tata"];
    const notificationType = ["Email", "Phone", "Sms"];
    const submit = () => {
      console.log(formData.value);
    };
    const formData = ref({});
    return {
      submit,
      usersGroup,
      notificationType,
      formData,
    };
  },
};
</script>

<style></style>
