<template>
  <v-container fluid>
    <div>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Sign Up</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="handleSubmit">
              <v-text-field
                prepend-inner-icon="mdi-map-marker"
                variant="outlined"
                name="email"
                label="e-mail"
                type="email"
                v-model="email"
                required
              ></v-text-field>
              <v-text-field
                id="password"
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                prepend-inner-icon="mdi-map-marker"
                variant="outlined"
                name="password"
                label="Password"
                type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                required
              ></v-text-field>
              <v-text-field
                id="organization"
                prepend-inner-icon="mdi-map-marker"
                variant="outlined"
                name="organization"
                label="organization"
                type="text"
                v-model="organization"
                required
              ></v-text-field>
            </v-form>
            <v-container fluid>
              <v-row>
                <v-col cols="4" sm="4" md="4">
                  <v-checkbox
                    v-model="isAdmin"
                    label="Admin"
                    color="red"
                    value="false"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4" sm="4" md="4">
                  <v-checkbox
                    v-model="isSimpleUser"
                    label="User"
                    color="green"
                    value="User"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="handleSubmit">Sign up</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </div>
  </v-container>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "LoginView",
  setup() {
    const email = ref("");
    const password = ref("");
    const organization = ref("");
    const isAdmin = ref("");
    const isSimpleUser = ref("");
    const error = ref("");
    const showPassword = ref(false);

    const store = useStore();
    const router = useRouter();

    const handleSubmit = async () => {
      //console.log(email.value, password.value);
      try {
        await store.dispatch("signup", {
          email: email.value,
          password: password.value,
          organization: organization.value,
          isAdmin: isAdmin.value,
        });
        router.push("/Dashboard");
      } catch (err) {
        console.log(err);
        error.value = err.message;
      }
    };

    return {
      handleSubmit,
      email,
      password,
      organization,
      isAdmin,
      isSimpleUser,
      showPassword,
    };
  },
};
</script>

<style lang="scss">
@import "Signup.scss";
</style>
