import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import i18n from "./i18n";
import VueApexCharts from "vue3-apexcharts";
import Vue3EasyDataTable from "vue3-easy-data-table";
import VCalendar from "v-calendar";
import eventBus from "./plugins/enventBus";

loadFonts();

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(eventBus)
  .use(i18n) //https://vueschool.io/lessons/do-it-yourself-i18n
  .use(VueApexCharts)
  .use(VCalendar, {})
  .component("Vue3EasyDataTable", Vue3EasyDataTable)
  .mount("#app");
