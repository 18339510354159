<template>
  <v-container fluid>
    <div>
      <h3>Account settings</h3>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-window v-model="tab">
          <v-window-item>
            <account-settings-account
              :accountData="accountSettingData.account"
            ></account-settings-account>
          </v-window-item>

          <v-window-item>
            <account-settings-security></account-settings-security
          ></v-window-item>

          <v-window-item>
            <account-settings-info
              :informationData="accountSettingData.information"
            ></account-settings-info
          ></v-window-item>
        </v-window>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import AccountSettingsAccount from "./AccountSettingsAccount.vue";
import AccountSettingsSecurity from "./AccountSettingsSecurity.vue";
import AccountSettingsInfo from "./AccountSettingsInfo.vue";
import { ref } from "vue";
export default {
  name: "accountSettings",
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity,
    AccountSettingsInfo,
  },

  setup() {
    const tab = ref("");
    // tabs
    const tabs = [
      { title: "Account", icon: "mdi-account-outline" },
      { title: "Security", icon: "mdi-lock-open-outline" },
      { title: "Info", icon: "mdi-information-outline" },
    ];

    // account settings data
    const accountSettingData = {
      account: {
        avatarImg: require("@/assets/images/avatars/1.png"),
        username: "johnDoe",
        name: "john Doe",
        email: "johnDoe@example.com",
        role: "Admin",
        status: "Active",
        company: "Google.inc",
      },
      information: {
        bio: "The name’s John Deo. I am a tireless seeker of knowledge, occasional purveyor of wisdom and also, coincidentally, a graphic designer. Algolia helps businesses across industries quickly create relevant 😎, scaLabel 😀, and lightning 😍 fast search and discovery experiences.",
        birthday: "February 22, 1995",
        phone: "954-006-0844",
        website: "https://themeselection.com/",
        country: "USA",
        languages: ["English", "Spanish"],
        gender: "male",
      },
    };

    return {
      tab,
      tabs,
      accountSettingData,
      icons: {
        mdiAccountOutline: "mdi-account-outline",
        mdiLockOpenOutline: "mdiLock-open-outline",
        mdiInformationOutline: "mdi-information-outline",
      },
    };
  },
};
</script>

<style></style>
