<!-- .
<template>
  <v-container>
    <v-card>
      <v-card-title>
        <h2>Reset password</h2>
      </v-card-title>

      <v-card-text>
        <v-responsive class="mx-auto" max-width="344">
          <v-text-field
            label="Password"
            type="input"
            hint="Enter your password to access this website"
          ></v-text-field>
        </v-responsive>
      </v-card-text>
    </v-card>
  </v-container>
</template> -->

<template>
  <div class="auth-wrapper auth-v1 bg-green">
    <v-card
      class="auth-card mx-auto my-2 px-6 py-8"
      elevation="8"
      max-width="344"
    >
      <!-- <v-sheet color="green"> -->
      <v-img
        :src="require('@/assets/images/logos/GrosLogoGreen2_x2.svg')"
        alt="logo"
        contain
        class="me-3"
      ></v-img>
      <!-- </v-sheet> -->
      <v-card-title class="text-center"> New Password? 🔒 </v-card-title>
      <v-card-text class="text-center">
        Enter your email to receive instructions to reset your password
      </v-card-text>
      <v-form v-model="form" @submit.prevent="onSubmit">
        <!-- <v-text-field
          v-model="email"
          :readonly="loading"
          :rules="[required]"
          class="mb-2"
          clearable
          label="Email"
        ></v-text-field> -->
        <v-text-field
          label="Email"
          v-model="email"
          variant="outlined"
          clearable
        ></v-text-field>

        <v-btn
          :disabled="!form"
          block
          color="success"
          size="large"
          type="submit"
        >
          SEND RESET LINK
        </v-btn>
      </v-form>
      <v-card-text>
        <v-alert
          v-if="show === 'success'"
          variant="outlined"
          border="top"
          type="success"
          title="Reset password link"
          text="Password reset link has been sent to your email address."
        ></v-alert>
        <v-alert
          v-else-if="show === 'error'"
          variant="outlined"
          type="error"
          title="Reset password link"
          text="Error occured."
          border="top"
        ></v-alert>
      </v-card-text>
      <v-card-actions class="text-center">
        <v-btn block flat to="/login">Back to Login</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const email = ref("");
    const form = ref(false);
    let show = ref("");
    const store = useStore();
    const onSubmit = async () => {
      await store.dispatch("sessionStore/resetPasswordLink", {
        email: email.value,
      });

      show.value = store.state.sessionStore.message;
    };

    return {
      show,
      form,
      email,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "Login.scss";
</style>
<!-- 
<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card>
          <v-card-title class="primary">
            <span class="headline">Forgot Password</span>
          </v-card-title>
          <v-card-text>
            <v-form @submit.prevent="resetPassword">
              <v-text-field
                v-model="email"
                label="Email"
                required
                type="email"
              ></v-text-field>
              <v-btn color="primary" type="submit">Reset Password</v-btn>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn flat to="/login">Back to Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    resetPassword() {
      // Insérez ici la logique pour réinitialiser le mot de passe
      // Vous pouvez envoyer une demande à votre serveur pour gérer cette action
      console.log("Reset password request for email: ", this.email);
      // Redirigez l'utilisateur après la réinitialisation du mot de passe
      this.$router.push("/login");
    },
  },
};
</script> -->
