import axios from "axios";
import router from "@/router";
import store from "@/store";

import { accountService } from "./account.service";

const Axios = axios.create({
  baseURL: process.env.VUE_APP_URL,
  timeout: 5000,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "X-Requested-With": "Axios",
    withCredentials: true,
    role: "admin",
  },
});

Axios.interceptors.request.use((request) => {
  if (accountService.isLogged()) {
    request.headers.Authorization = "Bearer " + accountService.getToken();
  }
  return request;
});

Axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    console.log("==== Axios interceptor in main.js ====");
    console.log(error);

    if (!error.response) {
      // Erreur rzo
      //store.commit('displayNotif', {d: true, mes: error})
      return Promise.reject(error);
    } else {
      const originalRequest = error.config;
      console.log(originalRequest);
      if (error.response.status === 403 || error.response.status === 401) {
        store.dispatch("logoutSession");
        return router.push("/login");
      } else {
        // Erreur de l'API
        //store.commit('displayNotif', {d: true, mes: error.response.data.message})
        return Promise.reject(error);
      }
    }
  }
});

// axios.interceptors.response.use(undefined, function (error) {
//   if (error) {
//     console.log("==== Axios interceptor in main.js ====");
//     const originalRequest = error.config;
//     console.log(originalRequest);
//     if (error.response.status === 403 || error.response.status === 401) {
//       store.dispatch("logoutSession");
//       return router.push("/login");
//     }
//   }
// });

export default Axios;
