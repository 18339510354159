<template>
  <v-app>
    <Header />
    <Sidebar />
    <v-main class="content bg-surface">
      <!-- <div class="mx-10"> -->
      <!-- <v-container class="bg-surface-variant"> -->
      <v-container fluid>
        <router-view />
      </v-container>
      <!-- </div> -->
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "../header/Header.vue";
import Sidebar from "../sidebar/Sidebar.vue";
import Footer from "../footer/Footer.vue";
export default {
  name: "Layout_Name",
  components: { Footer, Header, Sidebar },
  setup() {},
};
</script>

<style lang="scss">
@import "Layout.scss";
</style>
