<template>
  <v-container>
    <h1>User/create</h1>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="firstName"
        :counter="10"
        :rules="nameRules"
        label="First name"
        required
      ></v-text-field>

      <v-text-field
        v-model="lastName"
        :counter="10"
        :rules="nameRules"
        label="Last name"
        required
      ></v-text-field>

      <v-text-field
        v-model="name"
        :counter="10"
        :rules="nameRules"
        label="Name"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        required
      ></v-text-field>

      <v-text-field
        v-model="phoneNumber"
        :counter="7"
        :error-messages="errors"
        label="Phone Number"
        type="number"
        required
      ></v-text-field>

      <v-text-field
        v-model="password"
        type="password"
        label="Password"
        required
      ></v-text-field>

      <v-text-field
        v-model="passwordConfirme"
        type="password"
        label="Password again"
        rules="[matchingPasswords]"
        required
      ></v-text-field>

      <!-- <v-text-field
        v-model="organization"
        :counter="10"
        :rules="nameRules"
        label="Organization"
        required
      ></v-text-field> -->

      <v-select
        v-model="role"
        :items="items"
        :rules="[(v) => !!v || 'Item is required']"
        label="Role"
        required
      ></v-select>

      <v-checkbox
        v-model="checkbox"
        :rules="[(v) => !!v || 'You must agree to continue!']"
        label="Do you agree?"
        required
      ></v-checkbox>

      <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        Validate
      </v-btn>

      <v-btn color="error" class="mr-4" @click="resetForm"> Reset Form </v-btn>

      <v-btn color="warning" @click="resetValidation"> Reset Validation </v-btn>
    </v-form>
    <v-container>
      <h2>Name : {{ name }}</h2>
      <h2>Email : {{ email }}</h2>
      <h2>Role : {{ role }}</h2>
      <h2>Org : {{ organization }}</h2>
      <h2>Valid : {{ valid }}</h2>
    </v-container>
  </v-container>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useStore } from "vuex";
export default {
  name: "CreateUser_Name",
  setup() {
    let valid = ref(true);

    let nameRules = [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ];
    let emailRules = [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ];

    let name = ref("");
    let firstName = ref("");
    let lastName = ref("");
    let phoneNumber = ref("");
    let organization = ref([]);
    let email = ref("");
    let password = ref(null);
    let passwordConfirme = ref(null);
    let role = ref([]);
    let checkbox = false;
    let items = ["ROOT", "ROLE_ADMIN", "ROLE_USER", "SIMPLE_USER"];

    const resetForm = () => {
      name.value = "";
      email.value = "";
      password.value = null;
      matchingPasswords.value = null;
      checkbox = true;
      role.value = null;
    };

    const store = useStore();
    const validate = async () => {
      //this.$refs.form.validate();
      try {
        organization.value = localStorage.getItem("organization");
        await store.dispatch("sessionStore/createUser", {
          firstName,
          lastName,
          name,
          email,
          phoneNumber,
          organization,
          role,
          password,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const matchingPasswords = () => {
      if (password == passwordConfirme) return true;
      else return false;
    };

    return {
      matchingPasswords,
      items,
      firstName,
      lastName,
      name,
      email,
      phoneNumber,
      organization,
      role,
      password,
      passwordConfirme,
      checkbox,
      emailRules,
      valid,
      nameRules,
      resetForm,
      validate,
    };
  },
};
</script>

<style></style>
