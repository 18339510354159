<template>
  <v-container>
    <v-card>
      <!-- <div class="d-flex flex-sm-row flex-column"> -->
      <!-- <div class="flex-grow-1"> -->
      <v-card-title class="align-start">
        <span class="me-3 text-h5">{{ name }}</span>
        <v-spacer></v-spacer>
        <!-- <span class="text-xs text--disabled cursor-pointer">View All</span> -->
      </v-card-title>
      <!-- </div> -->
      <v-card-text>
        <v-row>
          <v-col
            v-for="(state, i) in states"
            :key="i"
            cols="12"
            class="text-xs text--disabled cursor-pointer"
            >{{ state.label }} {{ state.value }}

            <v-switch
              v-if="state.action"
              v-model="statusList"
              label="ON"
              color="success"
              value="ON"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        {{ statusList }}
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const name = "Transmitter State";

    const states = [
      {
        label: "Battery",
        value: 4,
        action: false,
      },
      {
        label: "Active",
        value: "ON",
        action: true,
      },
    ];

    let statusList = ref([]);

    return {
      name,
      states,
      statusList,
    };
  },
};
</script>

<style></style>
