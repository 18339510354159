import http from "../http-commun";

// https://github.com/bezkoder/vuetify-file-upload/tree/master/src

class UploadFilesService {
  upload(file, onUploadProgress) {
    //let formData = new FormData();

    //formData.append("file", file);

    return http.post("api/v1/firmware", file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return http.get("/files");
  }
}

export default new UploadFilesService();
