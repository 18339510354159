<template>
  <div>
    <h3>Firmwares</h3>
    <DeviceFirmware />
  </div>
</template>

<script>
import DeviceFirmware from "./deviceFirmware.vue";
export default {
  components: {
    DeviceFirmware,
  },
  setup(props) {},
};
</script>

<style scoped></style>
