<template>
  <v-container fluid>
    <v-form class="mx-4">
      <h3>Create alert condition</h3>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="formData.name"
            label="Alert Name"
            variant="outlined"
            dense.multi-col-validation
            placeholder="Alert Name"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            :items="allUsersGroup"
            item-title="name"
            item-value="_id"
            v-model="formData.userGroup"
            label="Users group"
            variant="outlined"
          ></v-select>
        </v-col>
      </v-row>

      <!-- Liste de notification -->
      <div class="my-10">
        <h3>Set notification list</h3>

        <v-row>
          <v-col cols="12" md="6">
            <v-card class="overflow-y-auto" max-height="400">
              <v-banner class="justify-left text-h5 font-weight-light" sticky>
                List disponible
              </v-banner>
              <!-- <v-card-text>
                <v-text-field
                  density="compact"
                  variant="solo"
                  label="Search templates"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-text> -->
              <v-card-text>
                <ul>
                  <li v-for="(notif, i) in allNotificationList" :key="i">
                    <span
                      @click="addToNoticationList(allNotificationList, notif)"
                    >
                      {{ notif.name }}</span
                    >
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="overflow-y-auto" max-height="400">
              <v-banner class="justify-left text-h5 font-weight-light" sticky>
                List en cours
              </v-banner>
              <!-- <v-card-text>
                <v-text-field
                  density="compact"
                  variant="solo"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-text> -->
              <v-card-text>
                <ul>
                  <li v-for="(notif, i) in notificationList" :key="i">
                    <span
                      @click="
                        removeFromNotificationList(notificationList, notif)
                      "
                    >
                      {{ notif.name }}
                    </span>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <!-- class="my-4 bg-surface-variant" -->
      <div class="my-10">
        <h3>Set alert rules</h3>

        <v-card
          class="my-4"
          v-for="(item, index) in formData.rules"
          :key="index"
        >
          <v-card-title> Rule {{ index + 1 }}</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  :items="['Valeur', 'Moyenne mobile']"
                  v-model="formData.rules[index].valeur"
                  label="Type"
                  variant="outlined"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="formData.rules[index].inegalte"
                  :items="['Moins que', 'Plus que']"
                  label="About type"
                  variant="outlined"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Limit"
                  v-model="formData.rules[index].limit"
                  type="number"
                  variant="outlined"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  label="Delay"
                  v-model="formData.rules[index].delay"
                  type="number"
                  variant="outlined"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="ms-2"
              variant="outlined"
              size="small"
              color="red"
              @click="deleteRule(item)"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
      <v-row>
        <v-col>
          <v-btn @click="addNewRule()">Add new rule</v-btn>
        </v-col>
      </v-row>

      <div class="my-10">
        <h3>Set sensors list</h3>

        <v-row>
          <v-col cols="12" md="6">
            <v-card class="overflow-y-auto" max-height="400">
              <v-banner class="justify-left text-h5 font-weight-light" sticky>
                Sensors can add
              </v-banner>
              <!-- <v-card-title> Sensors can add </v-card-title> -->
              <!-- <v-card-text v-for="(sensor, i) in sensorsList" :key="i">
                    {{ sensor.id }}
                  </v-card-text> -->
              <v-card-text>
                <v-text-field
                  density="compact"
                  variant="solo"
                  label="Search templates"
                  append-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                  @click:append-inner="onClick"
                ></v-text-field>
              </v-card-text>
              <v-card-text>
                <ul>
                  <li v-for="(sensorId, i) in sensorsList" :key="i">
                    <span @click="addToAlertList(sensorsList, sensorId)">
                      {{ sensorId }}</span
                    >
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="overflow-y-auto" max-height="400">
              <v-banner class="justify-left text-h5 font-weight-light" sticky>
                Sensors in alert
              </v-banner>
              <v-card-text>
                <v-text-field
                  density="compact"
                  variant="solo"
                  label="Search templates"
                  append-inner-icon="mdi-magnify"
                  single-line
                  hide-details
                  @click:append-inner="onClick"
                ></v-text-field>
              </v-card-text>
              <v-card-text>
                <ul>
                  <li v-for="(sensorId, i) in sensorsAlertList" :key="i">
                    <span
                      @click="removeFromAlertList(sensorsAlertList, sensorId)"
                    >
                      {{ sensorId }}
                    </span>
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12">
          <v-btn color="success" @click="submit"> Submit </v-btn>
          <v-btn color="error" type="reset" outlined class="mx-2">
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";

export default {
  setup(props) {
    const alertName = ref("");
    const organizationName = ref("");
    const city = ref("");
    const country = ref("");
    const company = ref("");
    const email = ref("");
    const checkbox = ref(false);

    const totalAlerts = 10;

    const search = ref("");

    const allDatas = ref([]);
    //const newAlertConditions = ref([]);

    const usersGroup = ["toto", "Tata"];
    const addNewRule = () => {
      //allDatas.value.push({ id: 3, label: "Champ de texte 3" });
      formData.value.rules.push(rule);
      //newAlertConditions.value.push();
    };

    const getAlertCondition = () => {
      allDatas.value = [1];
      // store.dispash
    };

    const onClick = () => {};

    const rule = {
      valeur: "Valeur",
      inegalte: "Moins que",
      limit: "1",
      delay: "1",
    };
    const formData = ref({
      // id: "",
      name: "ALERT_NAME",
      userGroup: [],
      notificationIDs: [],
      rules: [rule],
      sensorsId: [],
      organization: "BANIRI",
    });

    const store = useStore();

    const submit = () => {
      formData.value.sensorsId = sensorsAlertList;
      formData.value.organization = curentOrg;
      try {
        store.dispatch("sensorsStore/createAlert", formData.value);
      } catch (error) {
        console.log(error.message);
      }

      //console.log("Sensors Alert dispash");
    };

    // Alert list
    const alerts = computed(() => store.state.sensorsStore.alerts);

    const userList = "";
    const formatDate = (timestamp) => {
      var day = moment(timestamp);
      return day;
    };

    let dialog = ref(false);
    let editedIndex = ref([]);
    let editedItem = ref();

    const editItem = (item) => {
      console.log("Item" + item);
      editedItem.value = item;
      dialog.value = true;
    };

    const updateAlert = (editedAlert) => {
      //console.log(editedIndex.value.map((i) => i.textContent));
      console.log("Index " + editedAlert.name);

      try {
        store.dispatch("sensorsStore/updateAlert", editedAlert);
      } catch (error) {
        console.log(error.message);
      }
    };

    const getAllUsersGroup = async () => {
      await store.dispatch("sensorsStore/getAllUsersGroup");
    };

    const allUsersGroup = computed(() => {
      return store.getters["sensorsStore/getUsersGroups"];
    });

    const deleteRule = (item) => {
      const index = formData.value.rules.indexOf(item);
      if (index > -1) formData.value.rules.splice(index, 1);
    };

    const close = () => {};

    let sensorsList = [];
    let sensorsToBeList = ref([]);
    let sensors = computed(() => store.state.sensorsStore.sensors);
    const getAllSensors = () => {
      try {
        store.dispatch("sensorsStore/getAllSensors");
      } catch (error) {
        console.log(error.message);
      }
    };

    sensorsList = computed(() => {
      //return store.getters.sensorsInfo;
      let data = [];
      store.state.sensorsStore.sensors.forEach((element) => {
        data.push(element._id);
        //sensorsToBeList.value.push(element.id);
      });

      return data;
    });

    let sensorsAlertList = ref([]);
    const addToAlertList = (arr, sensorId) => {
      console.log(sensorsList);
      var index = arr.indexOf(sensorId);
      //console.log(index);
      if (index > -1) {
        sensorsAlertList.value.push(sensorId);
        //sensorsAlertList.value = sensorsAlertList.value.filter(onlyUnique);
        sensorsAlertList.value = [
          ...new Set(sensorsAlertList.value.filter(onlyUnique)), //https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
        ];
        arr.splice(index, 1);
        //arr.splice(index, 1);
      }
    };

    const removeFromAlertList = (arr, sensorId) => {
      var index = arr.indexOf(sensorId);
      if (index > -1) {
        arr.splice(index, 1);
      }
    };

    let notificationList = ref([]);
    const addToNoticationList = (arr, item) => {
      var index = arr.indexOf(item);
      if (index > -1) {
        notificationList.value.push(item);
        notificationList.value = [
          ...new Set(notificationList.value.filter(onlyUnique)), //https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
        ];
        arr.splice(index, 1);
      }
    };

    const removeFromNotificationList = (arr, item) => {
      var index = arr.indexOf(item);
      if (index > -1) {
        arr.splice(index, 1);
      }
    };

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    //https://stackoverflow.com/questions/5767325/how-can-i-remove-a-specific-item-from-an-array
    // const removeItemOnce = (arr, value) => {
    //   var index = arr.indexOf(value);
    //   if (index > -1) {
    //     arr.splice(index, 1);
    //   }
    //   return arr;
    // };

    const curentOrg = computed(() => {
      const org = store.state.organizationStore.organization._id;
      //formData.value.organization = org;
      return org;
    });
    const getOrgId = () => {
      try {
        store.dispatch("organizationStore/getById");
      } catch (error) {
        console.log(error.message);
      }
    };

    const getAllNotificationList = () => {};
    const allNotificationList = computed(() => {
      const defaultNotif = [{ _id: "455", name: "NOTIF1" }];
      return defaultNotif;
    });

    onMounted(() => {
      getAllSensors();
      getOrgId();
      getAlertCondition();
      getAllNotificationList();
      getAllUsersGroup();
    });

    return {
      sensorsList,
      sensorsAlertList,
      sensorsToBeList,
      formData,
      editedItem,
      editedIndex,
      dialog,
      userList,
      alerts,
      alertName,
      organizationName,
      city,
      country,
      company,
      email,
      checkbox,
      totalAlerts,
      search,
      allDatas,
      usersGroup,
      allUsersGroup,

      notificationList,

      onClick,
      addNewRule,
      submit,
      formatDate,
      updateAlert,
      editItem,
      close,
      addToAlertList,
      removeFromAlertList,
      deleteRule,
      addToNoticationList,
      removeFromNotificationList,
      allNotificationList,
    };
  },
};
</script>

<style></style>
