import Axios from "./caller.service";
import store from "@/store";

let login = (credentials) => {
  return Axios.post("/auth/login", credentials);
};

let logout = () => {
  localStorage.removeItem("jwt");
};

let getToken = () => {
  return localStorage.getItem("jwt");
};

let saveToken = (token) => {
  localStorage.setItem("jwt", token);
};

let isLogged = () => {
  let token = localStorage.getItem("jwt");
  return !!token;
};

export const accountService = {
  login,
  logout,
  saveToken,
  getToken,
  isLogged,
};
