<template>
  <v-container>
    <v-navigation-drawer
      app
      clipped
      v-model="getDrawer"
      permanent
      theme="ligth"
      :rail="!getDrawer"
      :mini-variant-width="sidebarMinWidth"
      :class="{ 'drawer-mini': !getDrawer }"
      :width="sidebarWidth"
      class="mt-15"
    >
      <div>
        <!-- <div><CurrentOrg /></div> -->
        <!-- <span class="v-list-item grey--text text-sm-h6">Application</span> -->
        <!-- <v-container fluid> -->

        <!-- </v-container> -->
        <!-- <div class="mt-10"> </div> -->

        <!-- <v-img
          :src="require('@/assets/images/logos/groslogoblanc2.svg')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img> -->
        <!-- <v-slide-x-transition>
          <h2 class="app-title text--primary">BANIRI</h2>
        </v-slide-x-transition> -->

        <v-list>
          <template v-for="(item, i) in items">
            <v-row v-if="item.heading" :key="item.heading" align="center">
              <v-col cols="6" class="py-5">
                <span
                  style="padding-left: 32px"
                  class="text-body-1 subheader"
                  :class="item.heading && getDrawer ? 'show ' : 'hide'"
                >
                  {{ item.heading }}
                </span>
              </v-col>
              <v-col cols="6" class="text-center"> </v-col>
            </v-row>
            <v-divider
              v-else-if="item.divider"
              :key="i"
              dark
              class="my-4"
            ></v-divider>
            <v-list-group
              color="green"
              v-else-if="item.children && getDrawe"
              :key="item.title"
              v-model="item.model"
              append-icon=""
            >
              <template v-slot:prependIcon>
                <v-icon size="28">mdi-image-filter-none</v-icon>
              </template>
              <template v-slot:activator>
                <v-list-item-title class="grey--text">
                  {{ item.title }}
                </v-list-item-title>
              </template>
              <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                :to="child.link"
                link
              >
                <v-icon size="" :icon="child.icon"></v-icon>
                <v-list-item-title class="grey--text">
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-list-item
              color="green"
              v-else-if="getPermision(item)"
              :key="item.text"
              :href="item.href ? item.href : null"
              :to="item.link === '#' ? null : item.link"
              link
            >
              <!-- <v-icon
                size="28"
                :color="item.color ? item.color : ''"
                :icon="item.icon"
              ></v-icon> -->
              <template v-slot:prepend>
                <v-icon size="30" :icon="item.icon"></v-icon>
              </template>
              <v-list-item-title class="grey--text mytitle" link>
                {{ item.title }}
                <!-- <h2>{{ item.meta.role }}</h2> -->
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </div>
      <v-divider></v-divider>
    </v-navigation-drawer>

    <!-- <v-container fluid class="m-20"> -->
    <!-- <v-select
      v-model="orgSelected"
      :items="organizations"
      variant="plain"
      density="comfortable"
      @update:modelValue="onOrgChange"
    ></v-select> -->
    <!-- </v-container> -->
  </v-container>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from "vuex";
import CurrentOrg from "../currentOrg/CurrentOrg";

export default {
  name: "SidebarComponent",
  components: { CurrentOrg },

  setup() {
    const msg = "SIDEBAR";
    let currentUserInfo = ref({});
    // let orgSelected = ref("BANIRI");
    // const organizations = ["BANIRI", "Bar", "Fizz", "Buzz"];
    let orgSelected = ref("");
    const organizations = [""];

    const onOrgChange = computed(() => {
      //dispatch("orgSelected"),
      localStorage.setItem("organization", orgSelected.value);
    });

    const items = [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        link: "/dashboard",
        meta: {
          role: "user",
        },
      },
      // { divider: true },
      // { heading: "DEVICES" },
      {
        title: "Charts",
        icon: "mdi-chart-areaspline",
        link: "/charts",
        meta: {
          role: "user",
        },
      },
      {
        title: "Sensors",
        icon: "mdi-thermometer-water",
        link: "/sensors",
        meta: {
          role: "user",
        },
      },
      {
        title: "Devices",
        icon: "mdi-devices",
        link: "/devices",
        meta: {
          role: "user",
        },
      },

      // {
      //   title: "Groupe-capteurs",
      //   icon: "mdi-thermometer-water",
      //   link: "/sensors",
      // },
      {
        title: "Streams",
        icon: "mdi-view-stream",
        link: "/streams",
        meta: {
          role: "user",
        },
      },
      {
        title: "Settings",
        icon: "mdi-cog-outline",
        link: "/create-sensors",
        meta: {
          role: "user",
        },
      },
      { divider: true },
      // { heading: "USERS" },
      // {
      //   title: "Settings",
      //   icon: "mdi-cog-outline",
      //   link: "/account-settings",
      // },

      // ONLY FOR ADMIN
      //      { divider: true },
      //     { heading: "ADMINISTRATION" },
      // {
      //   title: "Organizations",
      //   icon: "mdi-cog-outline",
      //   link: "/organizations-settings",
      // },
      {
        title: "Orgs",
        icon: "mdi-domain",
        link: "/orgs-manager",
        meta: {
          role: "supervisor",
        },
      },
      {
        title: "Users",
        icon: "mdi-account-supervisor",
        link: "/users-manager",
        meta: {
          role: "supervisor",
        },
      },
      {
        title: "Firmwares",
        icon: "mdi-domain",
        link: "/firmwares",
        meta: {
          role: "supervisor",
        },
      },
      // {
      //   title: "Sensors",
      //   icon: "mdi-chart-areaspline",
      //   link: "/sensors",ß
      // },
      // { title: "Alerts", icon: "mdi-alert-box", link: "/alerts" },
      // {
      //   title: "Notifications",
      //   icon: "mdi-bell",
      //   link: "/notifications",
      // },
      // {
      //   title: "Users",
      //   icon: "mdi-account-box",
      //   link: "/users",
      // },
      // { divider: true },
      // { heading: "DEVICES" },
      // {
      //   title: "Update",
      //   icon: "mdi-update",
      //   link: "/update",
      //   model: false,
      //   color: "warning",
      // },
    ];

    const store = useStore();

    const getDrawer = computed(() => {
      return !store.getters.drawerState;
    });

    const selectOrganization = computed(() => {
      currentUserInfo = {};
    });

    const getPermision = (item) => {
      if (
        // item.title == "Orgs" ||
        // item.title == "Users" ||
        // (item.title == "Firmwares" && item.meta.role == "supervisor") ||
        item.title == "Dashboard"
      ) {
        return true;
      }
      return false;
    };

    const getPermisionOld = (item) => {
      return false;
    };

    let sidebarWidth = 240;
    let sidebarMinWidth = 96;
    let expandOnHover = true;
    let rail = true;
    return {
      onOrgChange,
      items,
      msg,
      getDrawer,
      sidebarWidth,
      sidebarMinWidth,
      expandOnHover,
      rail,
      currentUserInfo,
      organizations,
      selectOrganization,
      getPermision,
    };
  },
};
</script>

<style lang="scss">
@import "Sidebar.scss";
.mytitle {
  font-size: 1.5rem !important;
}
</style>
