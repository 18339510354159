<template>
  <v-card color="grey lighten-4" flat height="200px" tile>
    <v-toolbar collapse>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-toolbar>
  </v-card>
</template>

<script>
export default {
  name: "Alert_Name",
};
</script>

<style></style>
