<template>
  <div class="auth-wrapper auth-v1 bg-green">
    <div class="auth-inner">
      <v-card class="auth-card" elevation="8" max-width="448" rounded="lg">
        <v-card-title class="d-flex align-center justify-center py-7">
          <v-img
            :src="require('@/assets/images/logos/GrosLogoGreen2_x2.svg')"
            alt="logo"
            contain
            class="me-3"
          ></v-img>
        </v-card-title>
        <v-card-text class="text-center">
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome to Baniri! 👋🏻
          </p>
          <p class="mb-2">
            Please sign-in to your account and start the adventure
          </p>
        </v-card-text>
        <v-card-text>
          <v-form>
            <v-text-field
              prepend-inner-icon="mdi-account-outline"
              variant="outlined"
              name="login"
              label="Login"
              type="text"
              v-model="email"
              required
            ></v-text-field>
            <v-text-field
              id="password"
              prepend-inner-icon="mdi-lock"
              variant="outlined"
              name="password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append-inner="showPassword = !showPassword"
              required
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me" hide-details class="me-3 mt-1">
              </v-checkbox>

              <router-link :to="{ name: 'resetpasswordlink' }">
                Forgot Password?
              </router-link>
            </div>
          </v-form>
        </v-card-text>
        <!-- divider -->
        <v-card-actions>
          <!-- <v-spacer></v-spacer> -->
          <v-btn
            block
            color="green"
            size="large"
            variant="tonal"
            class="mt-4"
            @click="onSubmit"
          >
            Login
          </v-btn>
          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
        <!-- <v-divider class="mx-4"></v-divider> -->
        <!-- <v-container>
              <div
                v-if="error"
                class="text-h6 flex-grow-1 text-center text-red"
              >
                {{ error }}
              </div>
            </v-container> -->

        <v-container>
          <v-alert
            v-if="show === 'success'"
            variant="outlined"
            border="top"
            type="success"
            title="Login"
            text="Login success!"
          ></v-alert>
          <v-alert
            v-else-if="show === 'error'"
            variant="outlined"
            border="top"
            type="error"
            title="Login"
            text="Invalid username or password"
          ></v-alert>
        </v-container>
      </v-card>
    </div>

    <img
      class="auth-mask-bg"
      height="173"
      :src="
        require(`@/assets/images/misc/mask-${
          $vuetify.theme.dark ? 'dark' : 'light'
        }.png`)
      "
    />

    <!-- tree -->
    <!-- <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img> -->

    <!-- <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img> -->
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    let show = ref("");
    let showPassword = ref(false);
    const error = ref("");
    const store = useStore();

    const state = computed(() => store.state.user);

    const onSubmit = async () => {
      await store.dispatch("sessionStore/login", {
        email: email.value,
        password: password.value,
      });
      show.value = store.state.sessionStore.message;
    };

    const theErrr = computed(() => store.state.sessionStore.message);

    const dialog = true;
    const valid = true;
    const firstName = "";
    const lastName = "";
    const n_email = "";
    const n_password = "";
    const verify = "";
    const loginPassword = "";
    const loginEmail = "";
    const loginEmailRules = [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ];
    const emailRules = [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ];

    const show1 = false;
    const rules = {
      required: (value) => !!value || "Required.",
      min: (v) => (v && v.length >= 8) || "Min 8 characters",
    };

    return {
      onSubmit,
      email,
      password,
      showPassword,
      error,
      show,
      theErrr,
      state,
      dialog,
      valid,
      firstName,
      lastName,
      n_email,
      n_password,
      verify,
      loginPassword,
      loginEmail,
      loginEmailRules,
      emailRules,
      show1,
      rules,
    };
  },
};
</script>

<style lang="scss">
@import "Login.scss";
</style>
