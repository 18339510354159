<template>
  <h1>OTA UPDATE DEVICE</h1>
</template>

<script>
export default {
  name: "Update_Name",
};
</script>

<style></style>
