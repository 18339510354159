<template>
  <v-app-bar class="main-header" height="64" dark fixed color="green">
    <v-btn flat icon class="mx-1" color="#C8E6C9" @click.stop="setDrawer">
      <template v-if="!getDrawer">
        <v-icon style="font-size: 28px">mdi-arrow-left</v-icon>
      </template>
      <template v-else>
        <v-icon style="font-size: 28px">mdi-menu</v-icon>
      </template>
    </v-btn>
    <v-app-bar-title>
      <v-img
        :src="require('@/assets/images/logos/baniri.svg')"
        max-height="130px"
        max-width="130px"
        alt="logo"
        contain
        class="d-none d-sm-flex"
      ></v-img>
      <!-- <span class="text-h4">BANIRI</span> -->
    </v-app-bar-title>
    <!-- <v-toolbar-title>BANIRI {{ getDrawer }}</v-toolbar-title> -->

    <v-spacer></v-spacer>
    <!-- <v-app-bar-title>{{ currentUserInfo.organization }}</v-app-bar-title> -->
    <!-- <v-app-bar-title>{{ organization }}</v-app-bar-title> -->

    <!-- {{ getDrawer }} -->
    <v-spacer></v-spacer>
    <!-- <v-col cols="1" sm="4" md="1" v-if="orgNbr">
          <div class="d-flex flex-column">
            <v-select
              v-model="orgSelected"
              :items="organizations"
              variant="plain"
              density="comfortable"
              @update:modelValue="onOrgChange()"
            ></v-select>
          </div>
        </v-col>
      </v-row> -->
    <CurrentOrg />

    <div>
      <v-menu
        min-width="180"
        top
        offset-y
        transition="slide-y-transition"
        bottom
        nudge-bottom="10"
        anchor="start"
      >
        <template v-slot:activator="{ props }">
          <v-btn class="mx-5" icon color="#C8E6C9" light v-bind="props" hidden>
            <v-icon style="font-size: 28px" color="white">mdi-translate</v-icon>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="300">
          <v-list>
            <v-list-item
              v-for="(item, i) in languageItems"
              :key="i"
              :value="item"
              active-color="primary"
              variant="plain"
            >
              <v-list-item-title
                v-text="item.text"
                @click="item.action"
              ></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>

    <v-btn variant="text" :icon="toggleIcon()" @click="toggleTheme"></v-btn>
    <!-- <v-btn variant="text" :icon="icons.mdiBellOutline"></v-btn> -->
    <!-- https://vuetifyjs.com/en/components/badges/ -->
    <v-btn class="text-none" stacked hidden>
      <v-badge content="2" color="blue">
        <v-icon>mdi-bell-outline</v-icon>
      </v-badge>
    </v-btn>

    <div class="text-center">
      <v-menu
        min-width="180"
        top
        offset-y
        transition="slide-y-transition"
        bottom
        nudge-bottom="10"
        anchor="start"
      >
        <template v-slot:activator="{ props }">
          <!-- <span my-5>{{
            currentUserInfo.email ? currentUserInfo.email : `Loading...`
          }}</span> -->
          <v-btn class="mx-5" icon color="#C8E6C9" light v-bind="props">
            <v-icon style="font-size: 28px" color="white">mdi-account</v-icon>
            <!-- <v-avatar size="40px" v-bind="attrs" v-on="on">
              <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
            </v-avatar> -->
          </v-btn>
          <!-- <v-avatar size="40px" v-bind="attrs" v-on="on">
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar> -->
        </template>
        <!-- <v-list>
        <v-list-item prepend-inner-icon="mdi-map-marker">
          My Acount
        </v-list-item>
        <v-list-item> Calendar </v-list-item>
        <v-list-item> Inbox </v-list-item>
        <v-list-item> Logout </v-list-item>
        <div class="d-flex justify-center my-3">
          <v-btn
            width="90%"
            small
            outlined
            color="success"
            class="text-capitalize"
            @click="logout"
            >Sign Out
          </v-btn>
        </div>
      </v-list> -->
        <!-- https://next.vuetifyjs.com/en/components/lists/ -->
        <v-card class="mx-auto" max-width="300">
          <v-list>
            <v-list-item
              v-for="(item, i) in userMenuItems"
              :key="i"
              :value="item"
              :title="item.text"
              @click="item.action"
              variant="plain"
            >
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
// https://next.vuetifyjs.com/en/components/menus/
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useTheme } from "vuetify";
import { useI18n } from "vue3-i18n";
import CurrentOrg from "../currentOrg/CurrentOrg";

//import localStorage from "../../store/modules/crytoStore";
export default {
  name: "Header_Name",
  components: { CurrentOrg },
  setup() {
    const msg = "Plateform in dev!";
    const store = useStore();
    const router = useRouter();

    const i18n = useI18n();
    const setLanguage = (lang) => {
      i18n.setLocale(lang);
    };

    const organization = computed(() => {
      return localStorage.getItem("organization");
    });

    let currentUserInfo = computed(() => {
      // let email = store.getters.userEmail;
      // let orgs = store.getters.organizations;
      // let email = store.getters["sessionStore/getProfile"].email;
      let organizations =
        store.getters["sessionStore/getProfile"].organizations;
      let email = localStorage.getItem("user");
      let organization = localStorage.getItem("organization");

      return { email, organization, organizations };
    });

    onMounted(() => {
      // const email = store.getters.userEmail;
      // const orgs = store.getters.organizations;
      const session = store.state.sessionStore.session;
      //theme.global.name.value = session.preferencies.theme;
      //console.log(localStorage.getItem("pref"));
      theme.global.name.value = JSON.parse(localStorage.getItem("pref")).theme;
      let email = localStorage.getItem("user");
      let organization = localStorage.getItem("organization");
      let organizations =
        store.getters["sessionStore/getProfile"].organizations;
      currentUserInfo = { email, organization, organizations };
    });

    const account = [
      { text: "Profile", icon: "mdi-account", color: "textColor" },
      { text: "Tasks", icon: "mdi-thumb-up", color: "textColor" },
      { text: "Messages", icon: "mdi-flag", color: "textColor" },
    ];
    const searchCollapse = ref(true);
    const notifications = ref([
      {
        text: "Check out this awesome ticket",
        icon: "mdi-tag",
        color: "warning",
      },
      {
        text: "What is the best way to get ...",
        icon: "mdi-thumb-up",
        color: "success",
      },
      {
        text: "This is just a simple notification",
        icon: "mdi-flag",
        color: "error",
      },
      {
        text: "12 new orders has arrived today",
        icon: "mdi-cart",
        color: "primary",
      },
    ]);
    const messages = ref([
      {
        text: "JH",
        name: "Jane Hew",
        message: "Hey! How is it going?",
        time: "09:32",
        color: "warning",
      },
      {
        text: "LB",
        name: "Lloyd Brown",
        message: "Check out my new Dashboard",
        time: "10:02",
        color: "success",
      },
      {
        text: "MW",
        name: "Mark Winstein",
        message: "I want rearrange the appointment",
        time: "12:16",
        color: "error",
      },
      {
        text: "LD",
        name: "Liana Dutti",
        message: "Good news from sale department",
        time: "14:56",
        color: "primary",
      },
    ]);

    const handleClick = () => {
      try {
        store.dispatch("login");
      } catch (error) {
        console.log(error);
      }
    };

    const setDrawer = () => {
      store.dispatch("toogleDrawer");
    };

    const getDrawer = computed(() => {
      return store.getters.drawerState;
    });

    const logout = () => {
      window.localStorage.setItem("authenticated", false);
      store.dispatch("sessionStore/logoutSession");
    };

    const settingAccount = () => {
      router.push("/account-settings");
    };

    const userMenuItems = [
      // { text: "My Acount", icon: "mdi-account", action: () => {} },
      // { text: "My Acount", icon: "mdi-account-outline", action: () => {} },
      // { text: "Calendar", icon: "mdi-calendar", action: () => {} },
      // { text: "Inbox", icon: "mdi-email-outline", action: () => {} },
      { text: "Logout", icon: "mdi-power", action: logout },
      //{ text: "Setting", icon: "mdi-cog-outline", action: settingAccount },
    ];

    const languageItems = [
      {
        text: "Francais",
        action: () => {
          setLanguage("fr");
        },
      },
      {
        text: "Anglais",
        action: () => {
          setLanguage("en");
        },
      },
    ];

    const icons = {
      mdiWeatherNight: "mdi-weather-night",
      mdiWeatherSunny: "mdi-weather-sunny",
      mdiBellOutline: "mdi-bell-outline",
    };

    const theme = useTheme();
    const currentItemIcon = ref("");
    const toggleTheme = () => {
      // theme.global.name.value = theme.global.current.value.dark
      // ? "light"
      // : "dark";
      if (theme.global.name.value == "light") theme.global.name.value = "dark";
      else theme.global.name.value = "light";

      store.dispatch("sessionStore/changePreferences", {
        theme: theme.global.name.value,
      });
    };

    const toggleIcon = () => {
      return theme.global.current.value.dark
        ? icons.mdiWeatherSunny
        : icons.mdiWeatherNight;
    };

    const getNotification = computed(() => {
      return 2;
    });

    return {
      msg,
      handleClick,
      setDrawer,
      logout,
      settingAccount,
      setLanguage,
      getDrawer,
      account,
      messages,
      notifications,
      searchCollapse,
      userMenuItems,
      currentUserInfo,
      organization,

      languageItems,
      icons,
      theme,
      toggleTheme,
      toggleIcon,
      getNotification,
      currentItemIcon,
    };
  },
};
</script>

<style lang="scss">
@import "Header.scss";
</style>
