<template>
  <router-view />
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
  created() {
    this.$eventBus.on("org-changed", (project) => {
      this.$router.push({
        name: "projects.monitoring",
        params: {
          id: project.id,
        },
      });
    });
  },
};
</script>

<style lang="scss">
@import "./styles/app.scss";
</style>
