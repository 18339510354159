/**
 * Souce : https://github.com/vuejs/vuex/tree/main/examples/composition/chat/store
 */
import { createStore, createLogger } from "vuex";
import * as getters from "./getters";
import * as actions from "./actions";
import mutations from "./mutations";
import state from "./state";

import sessionStore from "./modules/SessionStore";
import sensorsStore from "./modules/CreateSensorsStrore";
import organizationStore from "./modules/CreateOrganizationStore";
import deviceStore from "./modules/deviceStore";

const store = createStore({
  modules: {
    sessionStore,
    sensorsStore,
    organizationStore,
    deviceStore,
  },
  state,
  getters,
  actions,
  mutations,
  plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
});

export default store;
