<template>
  <v-container fluid>
    <v-toolbar prominent theme="ligth">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <v-toolbar-title>{{ $t("probes") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn> -->
      <v-dialog transition="dialog-bottom-transition" width="auto">
        <template v-slot:activator="{ props }">
          <v-btn variant="outlined" color="green" v-bind="props"> Create</v-btn>
        </template>
        <template v-slot:default="{ isActive }">
          <v-card>
            <v-toolbar color="success" title="Create Sensor"></v-toolbar>
            <v-card-text>
              <div class="text-h2 pa-12">IN DEVELOPPEMENT</div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn variant="text" @click="isActive.value = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-toolbar>
    <!-- </v-card> -->
  </v-container>

  <v-container fluid>
    <!-- {{ items }} -->
    <v-data-table-server
      :headers="headers"
      :items="items"
      :items-per-page="20"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-value="name"
      class="elevation-1"
    >
      <!-- <template v-slot:top>
        <v-toolbar>
          <v-toolbar-title>{{ $t("probes") }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template> -->

      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item.raw)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item.raw)"> mdi-delete </v-icon>
      </template>
    </v-data-table-server>
  </v-container>
</template>

<script>
import Axios from "@/_services/caller.service";
//import store from "@/store";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "@vue/runtime-core";

export default {
  setup() {
    //let items = ref([]);
    const searchField = ref("nmae");
    const searchValue = ref("");

    const store = useStore();

    const getAll = async () => {
      await store.dispatch("sensorsStore/getAllSensors");
    };

    const loading = ref(true);
    const items = computed(() => {
      loading.value = false;
      return store.getters["sensorsStore/getSensors"];
    });

    onMounted(async () => {
      await getAll();
    });

    const getDateTime = (timestamp) => {
      return new Date(timestamp * 1000).toLocaleString(); //toString();
    };

    const headers = ref([
      // { title: "ID", key: "_id" },
      { title: "STREAM", key: "streamId" },
      { title: "NAME", key: "name" },
      { title: "VALUE", key: "value" },
      { title: "UNIT", key: "unit" },
      //{ title: "ALERT", key: "alertConditionID" },
      //{ title: "USERS GR", key: "usersGroupIDs" },
      // { title: "ORG", key: "orgId.name", width: 200 },
      { title: "TIME", key: "timestamp" },
      { title: "ACTIONS", key: "actions" },
    ]);

    const itemsSelected = ref([]);

    /////
    let dialog = ref(false);
    let editedItem = ref();

    // TODO Deplacer vers le store
    const updateSensorInfo = async (editedItem) => {
      dialog.value = false;
      await Axios.put("/api/v1/sensors/" + editedItem.streamId, {
        //id: editedItem.sensorId,
        name: editedItem.name,
        unit: editedItem.unit,
        inactivityDelay: editedItem.inactivityDelay,
      })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => console.log(error));
    };

    const editItem = (item) => {
      //console.log("Item" + item.name);
      editedItem.value = item;
      dialog.value = true;
    };

    /////

    // setTimeout(() => {
    //   loading.value = false;
    // }, 1000);

    return {
      headers,
      items,
      itemsSelected,
      loading,
      dialog,
      editedItem,
      searchField,
      searchValue,
      getDateTime,
      editItem,
      updateSensorInfo,
    };
  },
};
</script>

<style>
/* .customize-table {
    --easy-table-header-font-size: 20px;
    --easy-table-body-row-font-size: 18px;
  } */
</style>
