<template>
  <v-container>
    <h3>NOTIFICATIONS</h3>
    <div>
      <router-link to="/notificationcreate"
        ><v-btn class="ma-2" outlined color="indigo">
          Create
        </v-btn></router-link
      >
    </div>
    <v-container>
      <v-table fixed-header height="300px">
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Calories</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in notificationGroups" :key="item.name">
            <td>{{ item.name }}</td>
            <td>{{ item.calories }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-container>
  </v-container>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "Notifications_Name",
  setup() {
    let search = ref();
    let notificationGroups = ref([]);
    const headers = [
      "Id",
      "Name",
      "Capteur",
      "Ajusté",
      "Lecture",
      "Création",
      "Modifier",
    ];
    return { headers, notificationGroups, search };
  },
};
</script>

<style></style>
