<template>
  <div>
    <h1>Organizations</h1>
    <v-card>
      <v-tabs v-model="tab">
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <v-window v-model="tab">
        <v-window-item>
          <create-organization />
        </v-window-item>

        <v-window-item>
          <update-organization />
        </v-window-item>

        <v-window-item>
          <list-organizations />
        </v-window-item>
      </v-window>
    </v-card>
  </div>
</template>

<script>
import CreateOrganization from "./CreateOrganization.vue";
import UpdateOrganization from "./UpdateOrganization.vue";
import ListOrganizations from "./ListOrganizations.vue";

import { ref } from "vue";

export default {
  components: {
    CreateOrganization,
    UpdateOrganization,
    ListOrganizations,
  },
  setup() {
    const tab = ref("");
    const tabs = [
      { title: "Create", icon: "mdi-account-outline" },
      { title: "Update", icon: "mdi-lock-open-outline" },
      { title: "List", icon: "mdi-information-outline" },
    ];
    return {
      tab,
      tabs,
    };
  },
};
</script>

<style></style>
