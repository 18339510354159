<template>
  <v-container>
    <v-card>
      <!-- <div class="d-flex flex-sm-row flex-column"> 
      <div class="flex-grow-1"> -->
      <v-card-title>
        <span class="me-3 text-h5">{{ name }}</span>
        <v-spacer></v-spacer>
        <span class="text-xs text--disabled cursor-pointer">View All</span>
      </v-card-title>
      <!-- </div>
    </div> -->
      <v-card-text> </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  setup() {
    const name = "Flow de données"; //"Data flow";

    return {
      name,
    };
  },
};
</script>

<style></style>
