<template>
  <v-container fluid>
    <div>
      <!-- {{ items }} -->
      <!-- {{ formData }} -->
      <!-- {{ accordingToCchannel }} -->
    </div>
    <v-form>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="formData.name"
            clearable
            label="Nom"
            type="text"
            variant="outlined"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            :items="allUsersGroup"
            v-model="formData.usersgroup"
            item-title="name"
            item-value="_id"
            label="Users group"
            variant="outlined"
          ></v-select>
        </v-col>
      </v-row>

      <v-card
        class="my-4"
        v-for="(item, index) in formData.receivers"
        :key="index"
      >
        <!-- <v-card-title>
          <span class="text-h5">{{ formTitle() }}</span>
        </v-card-title> -->

        <v-card-text>
          <v-row>
            <v-col cols="12" md="3">
              <v-select
                :items="channels"
                v-model="formData.receivers[index].channel"
                label="Type"
                variant="outlined"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                v-model="formData.receivers[index].user"
                :items="accordingToCchannel"
                item-title="email"
                item-value="_id"
                label="About type"
                variant="outlined"
              >
              </v-select>
              <!-- <v-text-field
                v-model="formData.receivers[index].contact"
                clearable
                label="contact"
                type="text"
                variant="outlined"
              >
              </v-text-field> -->
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="formData.receivers[index].othercontact"
                clearable
                label="other contact"
                type="text"
                variant="outlined"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                :items="['Email', 'Sms', 'Phone']"
                v-model="formData.receivers[index].contactmethod"
                label="Email-Sms-Phone"
                variant="outlined"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Delay"
                v-model="formData.receivers[index].delay"
                type="number"
                variant="outlined"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ms-2"
            variant="outlined"
            size="small"
            color="red"
            @click="deleteRule(item)"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-row>
        <v-col>
          <v-btn @click="addNewRule()">Add new rule</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color="success" @click="submit"> Submit </v-btn>
          <v-btn color="error" type="reset" outlined class="mx-2">
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const usersGroup = [];
    const channels = ["-", "User", "Email", "Phone", "Sms", "Wathsapp"];

    let aboutTypeData = [];
    const accordingToCchannel = computed(() => {
      UsersAndID = [...store.getters["sensorsStore/getUsersGroups"]];
      console.log(UsersAndID);
      aboutTypeData = UsersAndID.filter((elt) => {
        return elt._id === formData.value.usersgroup;
      });

      if (aboutTypeData.length == 0) {
        return [];
      } else {
        isNotUser = false;
        console.log(aboutTypeData[0].users);
        return aboutTypeData[0].users;
      }
    });

    let isNotUser = true;
    // let isNotUser = computed((index) => {
    //   //const index = formData.value.receivers.indexOf(item);
    //   //const state = formData.value.receivers[index].channel === "email";
    //   console.log("====================>");
    //   console.log(formData.value.receivers[index]);
    //   return true;
    // });

    const submit = () => {
      //console.log(formData.value);
      store.dispatch("sensorsStore/createNotificationList", formData.value);
    };

    const receiver = {
      user: "",
      channel: "",
      contactmethod: "",
      othercontact: "",
      delay: 0,
    };
    const formData = ref({
      name: "",
      usersgroup: "",
      description: "Nothing",
      receivers: [receiver],
      organization: localStorage.getItem("organization"),
    });

    const deleteRule = (item) => {
      const index = formData.value.receivers.indexOf(item);
      if (index > -1) formData.value.receivers.splice(index, 1);
    };

    const addNewRule = () => {
      //allDatas.value.push({ id: 3, label: "Champ de texte 3" });
      formData.value.receivers.push(receiver);
      //newAlertConditions.value.push();
    };

    const store = useStore();
    const getAllUsersGroup = async () => {
      await store.dispatch("sensorsStore/getAllUsersGroup");
    };

    let UsersAndID = [];
    const allUsersGroup = computed(() => {
      UsersAndID = store.getters["sensorsStore/getUsersGroups"];
      return store.getters["sensorsStore/getUsersGroups"];
    });

    onMounted(async () => {
      getAllUsersGroup();
    });

    return {
      submit,
      usersGroup,
      channels,
      formData,
      deleteRule,
      addNewRule,

      accordingToCchannel,

      allUsersGroup,
      aboutTypeData,
      UsersAndID,

      isNotUser,
    };
  },
};
</script>

<style></style>
