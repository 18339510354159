<template>
  <!-- <section class="section-container"> -->
  <div class="auth-wrapper auth-v1 bg-green">
    <v-row class="signin">
      <v-col cols="12" class="right">
        <v-container fluid fill-height>
          <v-card
            class="auth-card mx-auto pa-12 pb-8"
            elevation="8"
            max-width="448"
            rounded="lg"
          >
            <v-img
              :src="require('@/assets/images/logos/GrosLogoGreen2_x2.svg')"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
            <v-card-text>
              <v-form>
                <v-text-field
                  id="password"
                  prepend-inner-icon="mdi-lock"
                  variant="outlined"
                  name="Password"
                  label="Password"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPassword = !showPassword"
                  required
                ></v-text-field>

                <v-text-field
                  id="passwordConfirmation"
                  prepend-inner-icon="mdi-lock"
                  variant="outlined"
                  name="Password confirmation"
                  label="Password confirmation"
                  :type="showPassword ? 'text' : 'password'"
                  v-model="passwordConfirmation"
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPassword = !showPassword"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <!-- divider -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                block
                color="#00E676"
                size="large"
                variant="tonal"
                class="mt-6"
                @click="onSubmit"
              >
                RESET PASSEWORD
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-divider class="mx-4"></v-divider>
            <v-container>
              <v-alert
                v-if="show === 'success'"
                variant="outlined"
                border="top"
                type="success"
                title="Password reset"
                text="Password reset successfuly."
              ></v-alert>
              <v-alert
                v-else-if="show === 'error'"
                variant="outlined"
                border="top"
                type="error"
                title="Password reset"
                text="Error occured."
              ></v-alert>
            </v-container>
            <v-card-actions class="text-center">
              <v-btn block flat to="/login">Back to Login</v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const passwordConfirmation = ref("");
    let showPassword = ref(false);
    const message = ref("");
    let show = ref("");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const state = computed(() => store.state.user);

    const onSubmit = async () => {
      await store.dispatch("sessionStore/resetPassword", {
        password: password.value,
        passwordConfirmation: passwordConfirmation.value,
        userId: route.params.userId,
        token: route.params.token,
      });

      show.value = store.state.sessionStore.message;
      if (show.value === "success") {
        passwordConfirmation.value = "";
        password.value = "";
        message.value = "Password succefully reset";
      }
    };

    const theErrr = computed(() => store.state.sessionStore.message);

    const dialog = true;
    const valid = true;
    const firstName = "";
    const lastName = "";
    const n_email = "";
    const n_password = "";
    const verify = "";
    const loginPassword = "";
    const loginEmail = "";
    const loginEmailRules = [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ];
    const emailRules = [
      (v) => !!v || "Required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ];

    const show1 = false;
    const rules = {
      required: (value) => !!value || "Required.",
      min: (v) => (v && v.length >= 8) || "Min 8 characters",
    };

    return {
      onSubmit,
      show,
      email,
      password,
      passwordConfirmation,
      showPassword,
      message,
      theErrr,
      state,
      dialog,
      valid,
      firstName,
      lastName,
      n_email,
      n_password,
      verify,
      loginPassword,
      loginEmail,
      loginEmailRules,
      emailRules,
      show1,
      rules,
    };
  },
};
</script>

<style lang="scss">
@import "Login.scss";
</style>
