<template>
  <div class="d-flex flex-row-reverse mb-6">
    <v-btn
      class="ma-2 pa-2"
      color="success"
      variant="flat"
      @click="isGetAll = true"
    >
      Create
    </v-btn>
    <v-btn
      class="ma-2 pa-2"
      color="success"
      variant="flat"
      @click="getAllAlerts"
    >
      &nbsp;&nbsp;&nbsp; List &nbsp;&nbsp;&nbsp;
    </v-btn>
  </div>

  <v-container fluid>
    <div>
      <alert-condition-create v-if="isGetAll" />
    </div>
    <div v-if="isGetAll == false">
      <h3>Alerts condition list</h3>
      <v-row>
        <v-col cols="12">
          <!-- {{ alerts }} -->
          <!-- <v-btn color="primary" @click="getAllAlerts"> All Alerts</v-btn> -->
          <!-- <v-data-table-server
                :headers="headers"
                :items="alerts"
                :items-per-page="2"
                loading
                loading-text="Loading... Please wait"
                item-value="name"
                show-select
                show-expand
                class="elevation-1"
              >
                <template v-slot:expanded-row> This is an expanded row </template>
              </v-data-table-server> -->
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="alerts"
            :items-per-page="2"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-value="name"
            show-select
            show-expand
            class="elevation-1"
            :search="search"
          ></v-data-table>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment";
import AlertConditionCreate from "./AlertConditionCreate.vue";
export default {
  components: {
    AlertConditionCreate,
  },
  setup(props) {
    let loading = ref(true);
    const search = ref("");
    const store = useStore();

    const headers = [
      //{ title: "ID", sortable: false, key: "_id" },
      { title: "NAME", key: "name" },
      { title: "USERS GROUP", key: "users" },
      { title: "SENSORS GROUP", key: "sensors" },
      //{ title: "SENSORS", key: "sensors" },
      //{ title: "CREATED", key: "createdAt" },
      //{ title: "UPDATED", key: "lastUpdate" },
      { title: "ACTION", key: "action" },
    ];
    // Alert list
    const alerts = computed(() => store.state.sensorsStore.alerts);

    let isGetAll = ref(false);
    const getAllAlerts = () => {
      isGetAll.value = false;
      loading.value = true;
      try {
        store.dispatch("sensorsStore/getAllAlerts");
        loading.value = false;
      } catch (error) {
        console.log(error.message);
      }
    };

    let dialog = ref(false);
    let editedItem = ref();

    const editItem = (item) => {
      console.log("Item" + item);
      editedItem.value = item;
      dialog.value = true;
    };

    const updateAlert = (editedAlert) => {
      console.log("Index " + editedAlert.name);
      try {
        store.dispatch("sensorsStore/updateAlert", editedAlert);
      } catch (error) {
        console.log(error.message);
      }
    };

    onMounted(() => {
      getAllAlerts();
    });

    return {
      editedItem,
      dialog,
      loading,
      search,

      isGetAll,
      headers,
      alerts,

      getAllAlerts,
      updateAlert,
      editItem,
    };
  },
};
</script>

<style>
ul {
  list-style-type: none;
}
</style>
