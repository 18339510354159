<template>
  <!-- <h1>{{ title }}</h1> -->
  <div class="d-flex flex-row-reverse mb-6">
    <v-btn
      class="ma-2 pa-2"
      color="success"
      variant="flat"
      @click="isGetAll = true"
    >
      Create
    </v-btn>
    <v-btn class="ma-2 pa-2" color="success" variant="flat" @click="getAll">
      &nbsp;&nbsp;&nbsp; List &nbsp;&nbsp;&nbsp;
    </v-btn>
  </div>

  <!-- {{ items }} -->
  <!-- Create users list -->

  <!-- Listing users list -->
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="10"
    :loading="loading"
    loading-text="Loading... Please wait"
    item-value="name"
    show-select
    show-expand
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Users group</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <v-dialog persistent v-model="dialog">
          <template v-slot:activator="{ props }">
            <v-btn dark class="mb-2" v-bind="props"> New Item </v-btn>
          </template>
          <!-- <users-group-create></users-group-create> -->

          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle() }}</span>
            </v-card-title>

            <!-- <v-card-text>
                {{ datasList }}
              </v-card-text> -->

            <!-- {{ editedItem }} -->
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Name"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col>
                        <v-card class="overflow-y-auto">
                          <v-banner
                            class="justify-center text-h5 font-weight-light"
                            sticky
                          >
                            Users can add
                          </v-banner>
                          <!-- <div>
                              {{ datasListRest }}
                            </div> -->
                          <v-card-text>
                            <v-text-field
                              :loading="loading"
                              density="compact"
                              variant="solo"
                              label="Search templates"
                              append-inner-icon="mdi-magnify"
                              single-line
                              hide-details
                              @click:append-inner="onClick"
                            ></v-text-field>
                          </v-card-text>
                          <v-card-text>
                            <ul>
                              <li
                                v-for="(data, i) in datasListRestNew"
                                :key="i"
                              >
                                <span
                                  @click="addToList(datasListRestNew, data)"
                                >
                                  {{ data.email }}</span
                                >
                              </li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card class="overflow-y-auto">
                          <v-banner
                            class="justify-left text-h5 font-weight-light"
                            sticky
                          >
                            Users in group
                          </v-banner>
                          <!-- <div>
                              {{ datasToBeCreated }}
                            </div> -->
                          <v-card-text>
                            <v-text-field
                              :loading="loading"
                              density="compact"
                              variant="solo"
                              label="Search templates"
                              append-inner-icon="mdi-magnify"
                              single-line
                              hide-details
                              @click:append-inner="onClick"
                            ></v-text-field>
                          </v-card-text>
                          <v-card-text>
                            <ul>
                              <li
                                v-for="(data, i) in datasToBeCreated"
                                :key="i"
                              >
                                <span
                                  @click="
                                    removeFromList(datasToBeCreated, data)
                                  "
                                >
                                  {{ data.email }}
                                </span>
                              </li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Dialog de suppression d'une ligne du tableau -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this users:
              {{ editedItem.name }}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <!-- Affiche les utilisateurs du group -->
    <template v-slot:expanded-row="{ columns, item }">
      <tr>
        <td :colspan="columns.length">Users on group {{ item.raw.name }}</td>
      </tr>
      <tr>
        <td :colspan="columns.length">
          <span v-for="(item, index) in item.raw.users" :key="index">
            {{ item.email }} &nbsp;
          </span>
        </td>
      </tr>
    </template>

    <!-- Les bouton d'action sur chaque ligne du tableau -->
    <template v-slot:item.actions="{ item }">
      <v-icon size="small" class="me-2" @click="editItem(item.raw)">
        mdi-pencil
      </v-icon>
      <v-icon size="small" @click="deleteItem(item.raw)"> mdi-delete </v-icon>
    </template>

    <!-- Spinner de chargement du tableau -->
    <template v-slot:no-data>
      <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
      <v-progress-circular
        :size="70"
        :width="7"
        color="success"
        indeterminate
      ></v-progress-circular>
    </template>
  </v-data-table>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
//import UsersGroupCreate from "./UsersGroupCreate.vue";

import Axios from "@/_services/caller.service";

export default {
  //components: { UsersGroupCreate },
  setup() {
    const title = "Users Group List";

    const headers = [
      //{ title: "ID", key: "_id" },
      { title: "NAME", key: "name" },
      //{ title: "ORG", key: "orgID" },
      { title: "USERS", key: "usersemail" },
      { title: "CREATED", key: "createdAt" },
      { title: "UPDATE", key: "updatedAt" },
      { title: "ACTIONS", key: "actions" },
    ];

    let editedIndex = ref(-1);
    let editedItem = ref({ name: "", users: [] });
    let defaultItem = { name: "", users: [] };

    const formTitle = () => {
      return isEditedItem === false ? "New Item" : "Edit Item";
    };

    const dialog = ref(false);
    const dialogDelete = ref(false);
    const loading = ref(false);

    let itemIdTobeDelelete = 0;
    let isEditedItem = false;
    const editItem = (item) => {
      //editedIndex.value = this.items.indexOf(item);
      dialog.value = true;
      isEditedItem = true;
      editedItem.value = Object.assign({}, item);
      datasToBeCreated.value = [...item.users];
      datasListRestTmp.value = datasList.value.filter((obj1) => {
        return !datasToBeCreated.value.some(
          (obj2) => obj1._id === obj2._id && obj1.email === obj2.email
        );
      });
    };

    const datasListRestNew = computed(() => {
      return datasList.value.filter((obj1) => {
        return !datasToBeCreated.value.some(
          (obj2) => obj1._id === obj2._id && obj1.email === obj2.email
        );
      });
    });

    const closeDelete = () => {
      dialogDelete.value = false;
      //console.log(editedItem.value);
      //editedItem.value = Object.assign({}, {});
      //editedItem.value = defaultItem;
      editedIndex.value = -1;
    };

    const deleteItemConfirm = async () => {
      console.log("Item " + itemIdTobeDelelete);
      itemIdTobeDelelete = 0;
      store.dispatch("sensorsStore/deleteUsersGroup", editedItem.value._id);
      closeDelete();
      fetchUsers();
      await getAll();
    };

    const deleteItem = async (item) => {
      dialogDelete.value = true;
      //   editedIndex.value = this.items.indexOf(item);
      editedItem.value = Object.assign({}, item);
    };

    const close = () => {
      editedIndex.value = -1;
      dialog.value = false;
      isEditedItem = false;

      editedItem.value = defaultItem;
      //datasToBeCreated = [];
      //return datasListTotal;
      //datasToBeCreated = [];

      datasListRest.value = [...datasListRestTmp.value];
      // editedItem.value = Object.assign({}, {});
    };

    const store = useStore();

    const isGetAll = ref(false);
    const getAll = async () => {
      isGetAll.value = false;
      await store.dispatch("sensorsStore/getAllUsersGroup");
    };

    const items = computed(() => {
      let datas = store.getters["sensorsStore/getUsersGroups"];

      datas.forEach((item) => {
        item.usersemail = [];
        item.users.forEach((user) => {
          item.usersemail.push(user.email);
        });
      });

      return datas;
    });

    const save = async () => {
      isEditedItem = false;
      editedItem.value.users = [...datasToBeCreated.value];
      store.dispatch("sensorsStore/createUsersGroup", editedItem.value);
      fetchUsers();
      await getAll();
      close();
    };

    let datasList = ref([]);

    onMounted(async () => {
      fetchUsers();
      await getAll();
    });

    // Liste a creer
    let datasListRest = ref([]);
    let datasToBeCreated = ref([]);
    let datasListRestTmp = ref([]);
    // function onlyUnique(value, index, self) {
    //   return self.indexOf(value) === index;
    // }

    const addToList = (arr, data) => {
      var index = arr.indexOf(data);
      // console.log(index);
      if (index > -1) {
        arr.splice(index, 1);
        datasToBeCreated.value.splice(index, 0, data);
        // datasToBeCreated.value = [
        //   ...new Set(datasToBeCreated.value.filter(onlyUnique)), //https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
        // ];
      }
    };

    const removeFromList = (arr, data) => {
      var index = arr.indexOf(data);
      if (index > -1) {
        arr.splice(index, 1);
        datasListRest.value.splice(index, 0, data);
      }
    };

    ///////////////////////////////////
    // Specifique au composant
    let datasListTotal = [];
    const fetchUsers = async () => {
      await Axios.post("/api/v1/users", { role: "admin" })
        .then((res) => {
          datasList.value = res.data;
          res.data.forEach((user) => {
            const item = { _id: user._id, email: user.email };
            datasListRest.value.push(item);
            datasListTotal.push(item);
          });
          //defaultItem.users =
          //   res.data.forEach((user) => {
          //     user.orgsId.forEach((org) => user.organizations.push(org.name));
          //   });
        })
        .catch((error) => console.log(error));
    };

    const onClick = () => {};

    return {
      title,

      // Data table info
      headers,
      items,
      dialogDelete,
      dialog,
      formTitle,
      save,
      close,
      closeDelete,
      editItem,
      deleteItem,
      deleteItemConfirm,

      isGetAll,
      getAll,

      datasList,
      datasToBeCreated,
      datasListRest,
      datasListRestNew,
      addToList,
      removeFromList,
      editedItem,
      loading,
      onClick,
    };
  },
};
</script>

<style></style>
