<template>
  <div>
    <h1>List Organizations</h1>
    <v-row>
      <v-col cols="10"> </v-col>
      <v-col cols="1">
        <v-dialog v-model="dialogAddOrg" persistent>
          <template v-slot:activator="{ props }">
            <v-btn color="#64DD17" v-bind="props"> Add org </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Add org</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Legal first name*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Legal middle name"
                      hint="example of helper text only on focus"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      label="Legal last name*"
                      hint="example of persistent helper text"
                      persistent-hint
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="Email*" required></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Password*"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      :items="['0-17', '18-29', '30-54', '54+']"
                      label="Age*"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      :items="[
                        'Skiing',
                        'Ice hockey',
                        'Soccer',
                        'Basketball',
                        'Hockey',
                        'Reading',
                        'Writing',
                        'Coding',
                        'Basejump',
                      ]"
                      label="Interests"
                      multiple
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" text @click="dialogAddOrg = false">
                Close
              </v-btn>
              <v-btn color="blue-darken-1" text @click="dialogAddOrg = false">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- <v-btn color="primary" @click="isGetAlerts = true">Add org</v-btn> -->
      </v-col>
      <v-col cols="1">
        <v-btn color="#64DD17" @click="fetchOrg"> All Org</v-btn>
      </v-col>
      <v-container>
        <v-table fixed-header theme="light" mx-5>
          <thead>
            <tr>
              <th
                v-for="(header, index) in headers"
                :key="index"
                class="text-left"
              >
                {{ header.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(org, index) in organizations"
              :key="index"
              class="text-left"
            >
              <td>{{ org.id }}</td>
              <td>{{ org.name }}</td>
              <td>
                <v-dialog
                  v-model="dialog"
                  persistent
                  scrollable
                  max-width="500px"
                >
                  <template v-slot:activator="{ props }">
                    <v-icon
                      small
                      class="me-2"
                      v-bind="props"
                      @click="editItem(user)"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </template>
                  <v-card class="mx-auto my-12" min-width="800">
                    <v-card-title class="text-h5">Update user</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <div>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.id"
                              label="Alert Name"
                              outlined
                              dense.multi-col-validation
                              placeholder="Alert Name"
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.lastName"
                              label="Org Name"
                              outlined
                              dense
                              placeholder="Org Name"
                              hide-details
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="error" outlined @click="dialog = false">
                        Cancel
                      </v-btn>
                      <v-btn color="success" @click="updateItem(editedItem)">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-icon small @click="deleteItem(user.id)">
                  mdi-delete-outline
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-container>
    </v-row>
    {{ organizations }}
  </div>
</template>

<script>
import Axios from "axios";
import { useStore } from "vuex";
import { computed, ref } from "@vue/runtime-core";
export default {
  setup(props) {
    let dialogAddOrg = ref(false);
    const store = useStore();

    const fetchOrg = async (req, res) => {
      await store.dispatch("organizationStore/getAllOrganizations");
    };

    let organizations = computed(() => {
      return store.state.organizationStore.organizations;
    });

    let dialog = ref(false);
    let editedIndex = ref([]);
    let editedItem = ref();

    const editItem = (item) => {
      console.log("Item" + item.name);
      editedItem.value = item;
      dialog.value = true;
    };

    const deleteItem = async (id) => {
      const access_token = localStorage.getItem("jwt");
      await Axios.delete(url + "/api/org/" + id, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
        .then((res) => {
          //console.log(res);
          fetchUsers();
        })
        .catch((error) => console.log(error));
    };

    const updateItem = (item) => {
      dialog.value = false;
    };

    const headers = [
      { text: "ID", sortable: false, value: "id" },
      { text: "NAME", value: "full_name" },
      { text: "ACTIONS", value: "action" },
    ];

    return {
      fetchOrg,
      updateItem,
      deleteItem,
      editItem,
      headers,
      organizations,
      dialogAddOrg,
      editedIndex,
    };
  },
};
</script>

<style></style>
