// eventBus.js
// Je l'ai cree moi meme
// https://dzone.com/articles/why-and-how-to-create-an-event-bus-in-vuejs-3
import mitt from "mitt";

export default {
  install: (app, options) => {
    app.config.globalProperties.$eventBus = mitt();
  },
};
