// source https://www.youtube.com/watch?v=DL1wSt9Zjd4&t=323s
export default {
  version: 0,
  user: null,
  userId: null,
  token: null, //localStorage.setItem("token") || null,
  sensors: null,
  sensorsInfo: [],
  sensor: null,
  status: "",
  oserOrg: "",
  userEmail: "",
  loginFailure: "",
  drawer: true,
};
