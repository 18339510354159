<template>
  <v-container fluid>
    <h4>Guides</h4>
    <div class="ma-4">
      <v-row>
        <!-- <v-col cols="12"> -->
        <v-col sm="6" md="1"
          >Alert disabled<v-sheet
            :height="10"
            :width="50"
            color="warning"
          ></v-sheet
        ></v-col>
        <v-col sm="6" md="1"
          >Alert activated<v-sheet
            :height="10"
            :width="50"
            color="success"
          ></v-sheet
        ></v-col>
        <v-col sm="6" md="1"
          >Inactive<v-sheet
            :height="10"
            :width="50"
            color="grey-lighten-1"
          ></v-sheet
        ></v-col>
        <v-col sm="6" md="1"
          >Normal<v-sheet :height="10" :width="50" color="#adff2f"></v-sheet
        ></v-col>
        <v-col sm="6" md="1"
          >Low<v-sheet :height="10" :width="50" color="#ffff00"></v-sheet
        ></v-col>
        <v-col sm="6" md="1"
          >High<v-sheet :height="10" :width="50" color="#ff7f50"></v-sheet
        ></v-col>
        <v-col sm="6" md="1"
          >Alarm<v-sheet :height="10" :width="50" color="red"></v-sheet
        ></v-col>
        <!-- </v-col> -->
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  //name: "GuidesName",
};
</script>

<style></style>
