<template>
  <!-- <div v-if="getOrgLen" class="d-flex flex-column"> -->
  <div class="d-flex flex-column">
    <br />
    <v-select
      class="no-arrow"
      v-model="orgSelected"
      :items="orgsList"
      item-title="name"
      item-value="_id"
      density="compact"
      append-icon="search"
      hide-no-data
      @update:modelValue="onOrgChange"
    ></v-select>
    <!-- {{ orgsList }} -->
    <!-- {{ currentOrg }} -->
  </div>

  <!-- <div v-else>{{ orgsList[0].name }}</div> -->
  <!-- <div>{{ orgsList }}</div> -->
</template>

<script>
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
//import mitt from "mitt";

export default {
  name: "CurrentOrg",
  setup() {
    const devman = false;
    let orgSelected = ref("");
    let organizations = [];

    const store = useStore();

    //const emitter = mitt();
    const route = useRoute();
    const router = useRouter();

    const refreshRoute = () => {
      router.go({ path: route.path });
    };

    const onOrgChange = () => {
      //localStorage.setItem("organization", orgSelected.value);
      //emitter.emit("org-changed", orgSelected.value);
      updateCurrentOrg();
      fetchStreams();
      fetchDevices();
      fetchIotNodes();
      refreshRoute();
    };

    const fetchStreams = () => {};
    const fetchDevices = () => {};
    // Get sensors current information
    const fetchIotNodes = async () => {
      try {
        await store.dispatch(
          "getSensorsForUserOrg",
          localStorage.getItem("organization")
        );
      } catch (err) {
        console.log(err);
      }
    };

    const orgsList = ref([]);
    const currentOrg = ref("");

    const fetchOrgsOfUser = async () => {
      await store.dispatch("sessionStore/getAllOrgsOfUser");
      orgsList.value = store.state.sessionStore.orgsOfUser;
      currentOrg.value = store.state.sessionStore.currentOrg;
      orgSelected.value = currentOrg.value;
    };

    const updateCurrentOrg = async () => {
      try {
        await store.dispatch(
          "sessionStore/updateUsersCurrentOrgId",
          orgSelected.value
        );

        currentOrg.value = store.state.sessionStore.currentOrg;
        orgSelected.value = currentOrg.value;
      } catch (err) {
        console.log(err);
      }
    };

    onBeforeMount(async () => {
      //console.log("CurrentOrg: before mount");
      await fetchOrgsOfUser();
      await fetchIotNodes();
    });

    const getOrgLen = computed(() => {
      //console.log("Dashboard:===" + orgsList.value.length);
      fetchOrgsOfUser();
      //orgsList.value = store.state.sessionStore.orgsOfUser;
      return orgsList.value.length > 1;
    });

    // onMounted(() => {
    //   fetchOrgsOfUser();
    //   fetchIotNodes();
    // });

    return {
      organizations,
      orgSelected,
      orgsList,
      currentOrg,
      onOrgChange,
      devman,
      //lotOfOrg,
      getOrgLen,
    };
  },
};
</script>

<style scoped>
.no-arrow .v-select__selections .v-input__append-inner {
  display: none;
}
</style>
