// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

import { VDataTable, VDataTableServer } from "vuetify/labs/VDataTable";
import DateFnsAdapter from "@date-io/date-fns";
import enUS from "date-fns/locale/en-US";
import svSE from "date-fns/locale/sv";

export default createVuetify({
  components: {
    VDataTable,
    VDataTableServer,
  },
  date: {
    adapter: DateFnsAdapter,
    locale: {
      en: enUS,
      sv: svSE,
    },
  },
  defaults: {
    VDataTable: {
      fixedHeader: true,
      noDataText: "Results not found",
    },
  },
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
