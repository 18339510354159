import Axios from "@/_services/caller.service";
const urlDevicesApi = "/api/v1/devices/";

// initial state
const state = {
  all: [],
  devices: [],
};

// getters
const getters = {
  getDevices(state, getters) {
    return state.devices;
  },
};

// actions
const actions = {
  async update(context, orgInfo) {
    //console.log(orgInfo);
    await Axios.put("/api/v1/devices/update", orgInfo)
      .then((res) => console.log(res))
      .catch((error) => console.log(error));
  },

  async deleteById(context, id) {
    await Axios.delete("/api/v1/devices/" + id)
      .then((res) => {
        console.log(res);
      })
      .catch((error) => console.log(error));
  },

  async getAll(context) {
    //data = JSON.parse(JSON.stringify(alertData));
    await Axios.get("/api/v1/devices/")
      .then((res) => {
        //console.log(res.data);
        context.commit("setDevices", res.data);
      })
      .catch((error) => console.log(error));
  },

  async getAllByOrg(context) {
    //data = JSON.parse(JSON.stringify(alertData));
    //const org = localStorage.getItem("organization");
    const ordId = "63667aba09b80b87ccc838e7"; // no use by api server
    await Axios.get("/api/v1/devices/orgs/" + ordId)
      .then((res) => {
        //console.log(res.data);
        context.commit("setDevices", res.data);
      })
      .catch((error) => console.log(error));
  },
};

// mutations
const mutations = {
  setDevices(state, devices) {
    state.devices = devices;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
