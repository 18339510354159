<template>
  <v-container fluid>
    <h3>{{ $t("users") }}</h3>
    <div class="text-right">
      <v-dialog v-model="dialogAddUser" persistent>
        <template v-slot:activator="{ props }">
          <!-- <v-btn color="#64DD17" v-bind="props"> {{ $t("create") }} </v-btn> -->
          <v-btn class="mx-4" fab dark small color="#64DD17" v-bind="props">
            {{ $t("create") }}
          </v-btn>
        </template>
        <!-- <UsersCreate> </UsersCreate> -->
        <v-card>
          <v-card-title>
            <span class="text-h5">Create user</span>
          </v-card-title>
          <v-card-text>
            <!-- <v-container> -->
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="First name*"
                  v-model="formData.firstName"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Last name"
                  v-model="formData.lastName"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Username*"
                  v-model="formData.username"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email*"
                  v-model="formData.email"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <!-- :rules="phoneRules" -->
                <v-text-field
                  label="Phone"
                  v-model="formData.phone"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Password*"
                  type="password"
                  v-model="formData.password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <!-- item-value="_id" -->
                <v-autocomplete
                  :items="rolesList"
                  v-model="formData.role"
                  label="Roles*"
                  item-title="name"
                  item-value="name"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  :items="orgsList"
                  v-model="formData.orgs"
                  label="Organizations"
                  item-title="name"
                  item-value="_id"
                  multiple
                  required
                ></v-autocomplete>
              </v-col>
            </v-row>
            <!-- </v-container> -->
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue-darken-1" text @click="dialogAddUser = false">
              Close
            </v-btn>
            <v-btn color="blue-darken-1" text @click="createUser"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- <v-btn class="mx-2" fab dark small color="green"> Lister </v-btn> -->
      <v-btn class="mx-4" color="#64DD17" @click="fetchUsers">{{
        $t("userslist")
      }}</v-btn>
    </div>
    <div class="mt-10">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <!-- {{ $t("streams") }} -->
              <v-text-field
                v-model="searchValue"
                append-icon="mdi-magnify"
                label="rechercher"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div>
      <!-- {{ users }} -->
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
      item-value="name"
      class="elevation-1"
    >
      <template #item-action="item">
        <div>
          <v-dialog
            v-model="modifDialog"
            persistent
            scrollable
            max-width="500px"
          >
            <template v-slot:activator="{ props }">
              <v-icon small class="me-2" v-bind="props" @click="editItem(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <v-card class="mx-auto my-12" min-width="800">
              <v-card-title class="text-h5"
                >Update user infomations</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text> Modif dialog </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" outlined @click="modifDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="success"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-icon small @click="deleteItem(item._id)">
            mdi-delete-outline
          </v-icon>
          <!-- {{ item }} -->
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon size="small" class="me-2" @click="editItem(item.raw)">
          mdi-pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item.raw)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <!-- {{ users }} -->

    <!-- {{ orgsList }} -->

    <!-- {{ orgs }} -->
    <!-- {{ roles }} -->
    <!-- </v-container> -->
  </v-container>
</template>

<script>
import Axios from "@/_services/caller.service";
import AddUser from "./AddUser.vue";
import { onBeforeMount, onMounted, ref } from "@vue/runtime-core";
import UsersCreate from "./UsersCreate.vue";
export default {
  components: {
    UsersCreate,
    AddUser,
  },

  setup(props) {
    const searchValue = ref("");
    let loading = ref(false);
    let items = ref([]);

    const fetchUsers = async () => {
      // await axios.get(url + "/api/users", {
      await Axios.post("/api/v1/users", { role: "admin" })
        .then((res) => {
          items.value = res.data;
          res.data.forEach((user) => {
            user.orgsId.forEach((org) => user.organizations.push(org.name));
          });
        })
        .catch((error) => console.log(error));
    };

    onBeforeMount(() => {
      fetchUsers();
    });

    const getOrgs = (org) => {
      let orgsArray = [];
      org.forEach((element) => {
        orgsArray.push(element.name);
      });
      //console.log(orgsArray.join(","));
      return orgsArray.toString();
    };

    const headers = [
      { title: "ID", key: "_id" },
      { title: "FIRST NAME", key: "firstName" },
      { title: "LAST NAME", key: "lastName" },
      { title: "EMAIL", key: "email" },
      { title: "PHONE", key: "phone" },
      { title: "ORGS", key: "organizations" },
      { title: "ACTIONS", key: "actions" },
    ];

    let dialog = ref(false);
    let dialogAddUser = ref(false);
    let modifDialog = ref(false);
    let editedIndex = ref([]);
    let editedItem = ref();

    const editItem = (item) => {
      //console.log("Item" + item.name);
      editedItem.value = item;
      dialog.value = true;
    };

    const deleteItem = async (id) => {
      await Axios.delete("/api/v1/users/" + id)
        .then((res) => {
          //console.log(res);
          fetchUsers();
        })
        .catch((error) => console.log(error));
    };

    const updateUser = (item) => {
      dialog.value = false;
    };

    const firstName = ref("");
    const lastName = ref("");
    const username = ref("");
    const email = ref("");
    const phone = ref("");
    const password = ref("");
    const roles = ref([]);
    const orgs = ref([]);

    const formData = ref({
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      role: "",
      orgs: [],
    });

    const phoneRules = [
      (value) => {
        if (value) return true;

        return "E-mail is requred.";
      },
      (value) => {
        if (/.+@.+\..+/.test(value)) return true;

        return "Phone number must be valid.";
      },
    ];

    const createUser = async () => {
      dialogAddUser.value = false;
      await Axios.post("/api/v1/auth/signup", formData.value)
        .then((res) => {
          fetchUsers();
        })
        .catch((error) => console.log(error));
    };

    const createUser_old = async () => {
      dialogAddUser.value = false;
      await Axios.post("/api/v1/auth/signup", {
        firstName: firstName.value,
        lastName: lastName.value,
        username: username.value,
        email: email.value,
        phone: phone.value,
        password: password.value,
        //rolesName: roles.value,
        //rolesId: roles.value,
        role: roles.value,
        //orgsName: orgs.value,
        orgsId: orgs.value,
      })
        .then((res) => {
          fetchUsers();
        })
        .catch((error) => console.log(error));
    };

    onMounted(() => {
      getOrgsList();
      getRolesList();
    });

    // All organization
    const orgsList = ref();
    const getOrgsList = async () => {
      await Axios.get("/api/v1/orgs")
        .then((res) => {
          //console.log("============>" + res.data);
          orgsList.value = res.data;
        })
        .catch((error) => console.log(error));
    };

    const rolesList = ref();
    const getRolesList = async () => {
      await Axios.get("/api/v1/roles")
        .then((res) => {
          //console.log(res);
          rolesList.value = res.data;
        })
        .catch((error) => console.log(error));
    };

    const itemsSelected = ref([]);

    return {
      searchValue,
      fetchUsers,
      getOrgs,
      items,
      headers,
      itemsSelected,
      updateUser,
      editItem,
      deleteItem,
      modifDialog,
      dialog,
      dialogAddUser,
      editedItem,
      editedIndex,

      firstName,
      lastName,
      username,
      email,
      phone,
      password,
      roles,
      orgs,
      formData,
      createUser,
      orgsList,
      phoneRules,
      rolesList,

      loading,
    };
  },
};
</script>

<style></style>
