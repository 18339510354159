// export const getters = {
//   isLoggedIn: (state) => !!state.token,
//   aurhState: (state) => state.status,
//   user: (state) => state.user,
// };

export const sensors = (state) => state.sensors;
export const token = (state) => state.token;
export const user = (state) => state.user;
export const organizations = (state) => state.organizations;
export const getSensorsInfo = (state) => state.sensorsInfo;
export const userEmail = (state) => state.userEmail;
export const drawerState = (state) => state.drawer;
