<!--@Horace https://next.vuetifyjs.com/en/components/toolbars/ -->
<template>
  <v-card color="covGrey lighten-4" max-width="250px" min-width="200px">
    <v-hover v-slot="{ isHovering, props }">
      <v-toolbar :color="updateBarClass()" :dark="bar.dark">
        <!-- <v-toolbar-title> -->
        <v-card-text>
          <!-- <v-icon size="22" left>mdi-wifi</v-icon> -->
          <!-- <span class="text-h7"> {{ nameCompute(sensorData.name) }}</span> -->
          <span class="mx-1 text-subtitle-1 font-weight-medium text-left">
            {{ sensorData.name }}</span
          >
        </v-card-text>
        <!-- </v-toolbar-title> -->
        <v-spacer></v-spacer>
        <div class="mx-2">
          <v-icon size="22" left>{{ getBat() }}</v-icon>
          <v-icon size="22" left>{{ getLogo() }}</v-icon>
        </div>
      </v-toolbar>
      <v-container class="">
        <v-row>
          <v-col>
            <v-card-text>
              <!-- <div>{{ sensorData.alertLevel }}</div>
              <div>{{ sensorData.alertType }}</div>
              <div>{{ sensorData.alertAck }}</div> -->

              <v-row>
                <v-col>
                  <!-- <apexchart
                type="radialBar"
                height="350"
                :options="options1"
                :series="seriesCirc"
              ></apexchart> -->

                  <!-- chartOptionsCirc -->

                  <!-- <radial-progress-bar
                :total-steps="totalSteps"
                :completed-steps="completedSteps"
                :animate-speed="animateSpeed"
                :stroke-width="strokeWidth"
                :inner-stroke-width="innerStrokeWidth"
                :stroke-linecap="strokeLinecap"
                :start-color="startColor"
                :stop-color="stopColor"
                :inner-stroke-color="innerStrokeColor"
                :timing-func="timingFunc"
                :is-clockwise="isClockwise"
              >
                <div class="text-h4 text-center" cols="12">
                  {{ sensorData.value }} {{ unitCompute(sensorData.name) }}
                </div>
              </radial-progress-bar> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-h4 text-center" cols="12">
                  {{ roundVal(sensorData.value) }}
                  {{ unitCompute(sensorData.unit) }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="text-center">
                    <!-- Il y a {{ getTimeAgo(sensorData.timestamp) }} -->
                    {{ getTimeAgo(sensorData.timestamp) }} ago
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <!-- <v-spacer></v-spacer> -->
              <v-row>
                <v-col cols="3">
                  <v-dialog v-model="dialog" scrollable persistent>
                    <template v-slot:activator="{ props, isActive }">
                      <!-- <v-btn color="primary" v-bind="props"> Open Dialog </v-btn> -->
                      <!-- <v-icon small class="me-2" v-bind="props" @click="null">
                        mdi-chart-waterfall
                      </v-icon> -->
                      <v-btn
                        icon
                        small
                        class="mt-n2 me-n3"
                        v-bind="props"
                        @click="null"
                      >
                        <v-icon size="22"> mdi-chart-waterfall </v-icon>
                      </v-btn>
                    </template>
                    <!-- <v-container> -->
                    <v-card class="mx-auto">
                      <!-- <v-btn color="red" text @click="dialog = false">
                        Fermer
                      </v-btn>
                      -->
                      <v-toolbar dark color="green">
                        <v-btn icon dark @click="dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title extended
                          >Chart of {{ sensorData.name }}</v-toolbar-title
                        >
                        <!-- <v-spacer></v-spacer> -->
                        <!-- <v-toolbar-items>
                          <v-btn variant="text" @click="dialog = false">
                            Save
                          </v-btn>
                        </v-toolbar-items> -->
                      </v-toolbar>
                      <single-chart-vue :sensorData="sensorData">
                      </single-chart-vue>
                    </v-card>
                    <!-- </v-container> -->
                  </v-dialog>
                </v-col>
                <!-- </v-row>
            <v-row justify="center"> -->
                <v-col cols="3">
                  <v-dialog v-model="alert_dialog" justify="rigth" persistent>
                    <template v-slot:activator="{ props }">
                      <!-- <v-btn variant="outlined" color="" v-bind="props"
                    >Setting</v-btn
                  > -->
                      <!-- <v-icon small class="me-2" v-bind="props" @click="null">
                        mdi-cog-outline
                      </v-icon> -->
                      <v-btn
                        icon
                        small
                        class="mt-n2 me-n3"
                        v-bind="props"
                        @click="setAlertData()"
                      >
                        <v-icon> mdi-cog-outline </v-icon>
                      </v-btn>
                    </template>
                    <v-card class="mx-auto">
                      <v-toolbar dark color="green">
                        <v-btn icon dark @click="alert_dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title
                          >Setting {{ sensorData.name }}</v-toolbar-title
                        >
                      </v-toolbar>
                      <!-- <v-card-title>
                        <span class="text-h5"
                          >Setting {{ sensorData.name }}</span
                        >
                      </v-card-title> -->
                      <!-- {{ sensorData }} -->
                      <v-card-text>
                        <v-container fluid>
                          <h2 class="text-h5 my-5">Characteristic</h2>
                          <v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  v-model="sensorCharacteristic.name"
                                  label="name"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                              <!-- <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  v-model="sensorCharacteristic.unit"
                                  label="unit"
                                  persistent-hint
                                ></v-text-field>
                              </v-col> -->
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  v-model="sensorCharacteristic.inactivityDelay"
                                  label="Inactivity delay"
                                  type="number"
                                  persistent-hint
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-row>
                        </v-container>
                        <v-container fluid>
                          <h2 class="text-h5 my-5">Alert threshold</h2>
                          <v-row>
                            <!-- <v-col cols="12"> -->
                            <!-- <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                label="Name*"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-text-field
                                label="Localisation"
                                hint="example of helper text only on focus"
                              ></v-text-field>
                            </v-col> -->
                            <v-row>
                              <v-col cols="12">
                                <v-row>
                                  <v-col cols="12">
                                    <v-select
                                      :items="[
                                        'Ascending',
                                        'Descending',
                                        'Range',
                                      ]"
                                      v-model="alert.mode"
                                      label="Mode"
                                      hint="Alert mode"
                                      persistent-hint
                                    ></v-select>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="alert.low"
                                      label="low"
                                      hint="Alert low"
                                      type="number"
                                      persistent-hint
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="alert.lowDa"
                                      label="delay"
                                      hint="delay"
                                      type="number"
                                      persistent-hint
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="alert.high"
                                      label="High"
                                      hint="Alert high"
                                      type="number"
                                      persistent-hint
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="alert.highDa"
                                      label="delay"
                                      hint="delay"
                                      type="number"
                                      persistent-hint
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row v-if="alert.mode != 'Range'">
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="alert.alarm"
                                      label="Alarm"
                                      type="number"
                                      hint="Alert alarm"
                                      persistent-hint
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="alert.alarmDa"
                                      label="delay"
                                      hint="delay"
                                      type="number"
                                      persistent-hint
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                            <!-- </v-col> -->
                          </v-row>
                        </v-container>

                        <v-container fluid>
                          <!-- <h2>Means of notification</h2> -->
                          <h2 class="text-h5 my-5">Means of notification</h2>

                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-model="alertNotification.email"
                                label="email"
                                hint="email"
                                type="text"
                                persistent-hint
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="alertNotification.phone"
                                label="phone"
                                hint="phone"
                                type="number"
                                persistent-hint
                                disabled
                              ></v-text-field>
                            </v-col>
                            <!-- <v-col cols="12">
                              <v-select
                                :items="['email', 'sms', 'whatsapp']"
                                v-model="alertNotification.method"
                                label="Methode"
                                variant="outlined"
                              ></v-select>
                            </v-col> -->
                          </v-row>
                        </v-container>
                        <v-container fluid>
                          <v-row>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                                v-model="alertNotification.methods"
                                label="email"
                                color="success"
                                value="email"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                                v-model="alertNotification.methods"
                                label="sms"
                                color="success"
                                value="sms"
                                disabled
                                indeterminate
                              >
                              </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <v-checkbox
                                v-model="alertNotification.methods"
                                label="whatsapp"
                                color="success"
                                value="whatsapp"
                                disabled
                                indeterminate
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <small>*indicates required field</small>
                        </v-container>
                      </v-card-text>
                      <v-container fluid>
                        <v-card-text>
                          <v-switch
                            v-model="alert.active"
                            color="success"
                            :model-value="alert.active"
                            hide-details
                            :label="`Alert is ${alert.active}`"
                            inset
                          ></v-switch>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="red" text @click="alert_dialog = false">
                            Close
                          </v-btn>
                          <v-btn
                            color="green"
                            text
                            @click="
                              setSensorAlert(sensorData._id, sensorData.id)
                            "
                          >
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                      <!-- <template>
                        <v-alert
                          v-if="isSave"
                          type="success"
                          title="Alert title"
                          text="Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi, ratione debitis quis est labore voluptatibus! Eaque cupiditate minima, at placeat totam, magni doloremque veniam neque porro libero rerum unde voluptatem!"
                        ></v-alert>
                      </template> -->
                    </v-card>
                  </v-dialog>
                </v-col>

                <v-col cols="3">
                  <v-dialog v-model="certificat_dialog" scrollable persistent>
                    <template v-slot:activator="{ props, isActive }">
                      <v-btn
                        icon
                        small
                        class="mt-n2 me-n3"
                        v-bind="props"
                        @click="null"
                      >
                        <v-icon size="22"> mdi-certificate </v-icon>
                      </v-btn>
                    </template>
                    <v-card class="mx-auto">
                      <v-toolbar dark color="green">
                        <v-btn icon dark @click="certificat_dialog = false">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title
                          >Certificate of {{ sensorData.name }}</v-toolbar-title
                        >
                      </v-toolbar>
                      <sensor-cerificate></sensor-cerificate>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="3">
                  <v-dialog v-model="alert_ack_dialog" scrollable persistent>
                    <template v-slot:activator="{ props }">
                      <v-btn
                        icon
                        small
                        class="mt-n2 me-n3"
                        v-bind="props"
                        :color="getColor"
                        @click="getAlertAck()"
                      >
                        <v-icon> mdi-alert-circle-outline </v-icon>
                      </v-btn>
                    </template>
                    <v-container fluid>
                      <v-card class="mx-auto" max-width="500px">
                        <v-toolbar dark color="green">
                          <v-btn icon dark @click="alert_ack_dialog = false">
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                          <v-toolbar-title
                            >Acknowledged alert
                            {{ sensorData.name }}</v-toolbar-title
                          >
                        </v-toolbar>

                        <v-switch
                          class="mx-5"
                          v-model="alertAck"
                          color="success"
                          :model-value="alertAck"
                          hide-details
                          :label="`Ack is ${alertAck}`"
                          inset
                        ></v-switch>
                        <v-card-text class="text-center text-subtitle-1">
                          Acknowledge alert to receive a next notification
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red"
                            text
                            @click="alert_ack_dialog = false"
                          >
                            Close
                          </v-btn>
                          <v-btn color="green" text @click="setAlertAck()">
                            Save
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-container>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-actions>
            <!-- 
            <v-hover>
              <v-btn
                :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                @click="show = !show"
              ></v-btn>
            </v-hover> -->
            <!-- <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <apexchart
                  width="500"
                  type="line"
                  :options="sensorsChartOptions"
                  :series="sensorSeries"
                ></apexchart>
              </div>
            </v-expand-transition> -->
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
          sm="12"
          :class="updateAlertColor2(sensorData.alertType)"
        >
        </v-col>
      </v-row>
    </v-hover>
  </v-card>

  <!-- <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>T1</v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>T2</v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>T3</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container> -->
</template>

<script>
import {
  ref,
  computed,
  onMounted,
  onUpdated,
  onUnmounted,
  onBeforeMount,
} from "vue";
import RadialProgressBar from "vue3-radial-progress"; // https://github.com/jairoblatt/vue3-radial-progress?ref=vuejsexamples.com
import VueApexCharts from "vue3-apexcharts";
import SingleChartVue from "@/views/charts/singleChart.vue";
import { useStore } from "vuex";
import moment from "moment";
import mqtt from "mqtt/dist/mqtt";
import SensorCerificate from "./sensorCerificate.vue";

export default {
  name: "Sensor_Name",
  props: [
    "db_id",
    "name",
    "value",
    "numero",
    "unit",
    "low",
    "higth",
    "alarm",
    "sensorData",
  ],
  components: {
    apexchart: VueApexCharts,
    RadialProgressBar,
    SingleChartVue,
    SensorCerificate,
  },

  setup(props) {
    ///
    var options1 = {
      chart: {
        height: 280,
        type: "radialBar",
      },
      series: [67],
      colors: ["#20E647"],
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          track: {
            background: "#333",
            startAngle: -135,
            endAngle: 135,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              fontSize: "30px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          gradientToColors: ["#87D4F9"],
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "butt",
      },
      labels: ["Progress"],
    };

    let seriesCirc = ref([0]);
    let chartOptionsCirc = {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false, // true, pour desactiver le
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3, //3, // 1
              left: 0,
              blur: 4,
              opacity: 0.24, //0.24,  // 0.1
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3, //-3,  /// -1
              left: 0,
              blur: 4,
              opacity: 0.35, //0
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              // formatter: function (val) {
              //   return parseInt(val);
              // },
              color: "#111",
              fontSize: "40px", // 36
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          //gradientToColors: ["#ABE5A1"],
          gradientToColors: ["#F55555", "#6078ea", "#6094ea"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Percent"],
    };

    ///

    let show = ref(false);

    const sensorsChartOptions = ref({
      chart: {
        id: "Sensor chart",
      },
      title: {
        text: "Courbe des 10 dernieres mesures",
        align: "left",
      },
      tooltip: {
        x: {
          format: "HH:mm:ss",
        },
      },
      markers: {
        // pour les point sur la courbe
        size: 5,
      },
      dataLabels: {
        // Affiche la valeur du capteur sur la courbe
        //enabled: true,
      },
      stroke: {
        curve: "smooth",
        lineCap: "butt",
      },
      xaxis: {
        type: "datetime",
        format: "HH:mm:ss",
        labels: {
          datetimeUTC: false, //https://codepen.io/drpuur/pen/ExaQWGx
        },
        //tickAmount: 6,
      },
      yaxis: {
        title: {
          text: props.sensorData.name,
        },
        //min: 0,
        //max: 70,
      },
      points: [
        {
          x: props.sensorData.timestamp * 1000,
          y: props.sensorData.value,
          marker: {
            size: 8,
            fillColor: "#fff",
            strokeColor: "red",
            radius: 2,
            cssClass: "apexcharts-custom-class",
          },
          label: {
            borderColor: "#FF4560",
            offsetY: 0,
            style: {
              color: "#fff",
              background: "#FF4560",
            },

            text: "Point Annotation",
          },
        },
      ],
    });

    const store = useStore();
    // Get sensors history to display chart
    let sensorsHistory = ref([]);

    // onUpdated(() => {
    //   seriesCirc.value[0] = props.sensorData.value;
    //   sensorsHistory.value.push([
    //     props.sensorData.timestamp * 1000,
    //     props.sensorData.value,
    //   ]);
    //   if (sensorsHistory.value.length == 100) {
    //     sensorsHistory.value.shift();
    //   }
    // });

    //https://apexcharts.com/javascript-chart-demos/area-charts/datetime-x-axis/
    const sensorSeries = ref([
      {
        name: "Sensor",
        data: sensorsHistory,
      },
    ]);

    let isActive = ref(true);
    const updateAlertColor = (sensorVal, low, higth, alarm) => {
      isActive.value = true;
      if (sensorVal > alarm) {
        return { covClassAlertRed: isActive };
      } else if (sensorVal > higth) {
        return { covClassAlertRose: isActive };
      } else if (sensorVal > low) {
        return { covClassAlertYelow: isActive };
      }

      return { covClassAlertGreen: isActive };
    };

    // Gestion des alerts
    const alert = ref({
      //alert.value = props.sensorData.value.alertSingle;
      low: 0,
      lowDb: 0,
      lowDa: 0,
      high: 0,
      highDb: 0,
      highDa: 0,
      alarm: 0,
      alarmDb: 0,
      alarmDa: 0,
      model: "Range",
      active: false,
    });

    let alertNotification = ref({
      email: "",
      phone: "",
      method: "",
      methods: [],
    });

    const sensorCharacteristic = ref({
      name: "Know",
      inactivityDelay: 1200,
    });

    let alertAck = ref(false);

    const getAlertAck = async () => {
      alertAck.value = props.sensorData.alertAck;
    };

    const setAlertAck = async () => {
      alert_ack_dialog.value = false;
      await store.dispatch("sensorsStore/setAlertAck", {
        _id: props.sensorData._id,
        alertAck: alertAck.value,
      });
    };

    const bar = { class: "success", dark: true };
    const updateBarClass = () => {
      isActive.value = true;
      let state = "normal";
      if (props.sensorData.alertSingle.active == false) state = "activealert";
      if (props.sensorData.statusInfo.isInactive == true) state = "inactve";
      if (state == "normal") {
        bar.class = "success";
        return bar.class;
      } else if (state == "activealert") {
        bar.class = "warning";
        return bar.class;
      } else if (state == "inactve") {
        bar.class = "grey-lighten-1";
        return bar.class;
      }

      return { covClassAlertGreen: isActive };
    };

    // Gestion du dialog
    const dialog = ref(false);
    const alert_dialog = ref(false);
    const certificat_dialog = ref(false);
    const alert_ack_dialog = ref(false);

    const updateSensorValue = async (dbid) => {
      try {
        await store.dispatch("getSensorById", dbid);
      } catch (error) {
        console.log(error);
      }
    };

    let isSave = ref(false);
    const setSensorAlert = async (dbid, index) => {
      console.log("Set sensor alert " + dbid);
      alert_dialog.value = false;
      try {
        await store.dispatch("sensorsStore/setSingleSensorAlert", {
          id: dbid,
          channel: index,
          alertSingle: alert.value,
          alertSingleNotification: alertNotification.value,
          name: sensorCharacteristic.value.name,
          inactivityDelay: sensorCharacteristic.value.inactivityDelay,
        });
        //console.log(alert.value);
        console.log("Succes set alert");
        isSave.value = true;
      } catch (err) {
        console.log(err);
        isSave.value = false;
      }
    };

    const setAlertData = () => {
      alert.value = props.sensorData.alertSingle;
      //alertNotification.value = props.sensorData.alertSingleNotification;
      Object.assign(
        alertNotification.value,
        props.sensorData.alertSingleNotification
      );
      sensorCharacteristic.value.name = props.sensorData.name;
      sensorCharacteristic.value.inactivityDelay =
        props.sensorData.inactivityDelay;
    };

    const sensorlogo = ref("mdi-wifi");

    const getLogo = () => {
      const mac = props.sensorData._id;
      if (mac.length > 14) {
        sensorlogo.value = "mdi-cast"; // lora
      } else {
        sensorlogo.value = "mdi-wifi"; // wifi
      }
      return sensorlogo.value;
    };

    const batLevel = ref("mdi-battery-outline");
    const getBat = () => {
      const bat = props.sensorData.bat;

      if (bat > 3.5) batLevel.value = "mdi-battery"; /// max_value = 3.6
      else if (bat > 3.44) batLevel.value = "mdi-battery-90";
      else if (bat > 3.4) batLevel.value = "mdi-battery-80";
      else if (bat > 3.3) batLevel.value = "mdi-battery-70";
      else if (bat > 3.2) batLevel.value = "mdi-battery-60";
      else if (bat > 3.1) batLevel.value = "mdi-battery-50";
      else if (bat > 3) batLevel.value = "mdi-battery-40";
      else if (bat > 2.9) batLevel.value = "mdi-battery-30";
      else if (bat > 3.8) batLevel.value = "mdi-battery-20";
      else if (bat > 2.7) batLevel.value = "mdi-battery-10";

      return batLevel.value;
    };

    const unitCompute = (index) => {
      // &deg;C
      if (index === "TEMP") return "˚C";
      else if (index === "HUM") return "%";
      else if (index === "PRES") return "hPa";
      else if (index === "CO2") return "%";
      else if (index === "NH3") return "ppm";
      else if (index === "H2S") return "ppm";
      else if (index === "NO2") return "ppm";
      else if (index === "CO") return "ppm";
      else if (index === "LEV") return "cm";
      else if (index === "CM") return "cm";
    };

    const nameCompute = (index) => {
      // &deg;C
      if (index === "TEMP") return "TEMPERATURE";
      else if (index === "HUM") return "HUMIDITY";
      else if (index === "PRES") return "PRESSURE";
      else if (index === "CO2") return "CO2";
      else if (index === "NH3") return "NH3";
      else if (index === "H2S") return "H2S";
      else if (index === "NO2") return "NO2";
      else if (index === "CO") return "CO";
      else if (index === "LEV") return "OIL LEVEL";
    };

    const roundVal = (val) => {
      getAlertAck;
      let data = parseFloat(val);
      return data.toFixed(1);
    };
    const now = computed(() => Date.now());

    const noDataIntevalSeconde = 2100;
    const timeAgo = moment(now).fromNow(true);
    const getTimeAgo = (timestamp) => {
      let tmsp = moment().unix() - timestamp;
      if (tmsp < 0) tmsp = "0";
      // else if (tmsp > noDataIntevalSeconde) tmsp = "nan";

      let timeUnit = "s";
      if (tmsp > 86400) {
        tmsp = tmsp / 86400;
        tmsp = parseFloat(tmsp).toFixed(2);
        timeUnit = "j";
      } else if (tmsp > 3600) {
        tmsp = tmsp / 3600;
        tmsp = parseFloat(tmsp).toFixed(2);
        timeUnit = "h";
      } else if (tmsp > 60) {
        tmsp = tmsp / 60;
        tmsp = parseFloat(tmsp).toFixed(2);
        timeUnit = "min";
      }

      return tmsp + " " + timeUnit;
    };

    //onBeforeMount(async () => {});

    onMounted(() => {
      //seriesCirc.value[0] = props.sensorData.value;
      topic = "application/1/device/" + props.sensorData.macId + "/event/alert";
      //mqttConnect();
    });

    onBeforeMount(() => {
      seriesCirc.value[0] = props.sensorData.value;
    });

    onUnmounted(() => {
      //destroyConnection();
    });

    ///////////////////
    //MQTT Client
    const connectUrl = process.env.MATT_CLIENT_URL;
    const clientId = "mqttjs_" + Math.random().toString(16).substr(2, 8);
    let mqttClient;

    const options = {
      clean: true,
      connectTimeout: 4000,
      clientId: clientId,
      protocolId: "MQTT",
    };

    let topic = "";
    const mqttConnect = () => {
      try {
        mqttClient = mqtt.connect(connectUrl, options);
        console.log("Success");
      } catch (error) {
        console.log("mqtt.connect error", error);
      }

      mqttClient.on("connect", () => {
        mqttClient.subscribe(topic);
        console.log("Subscribe to topic : " + topic);
      });

      mqttClient.on("error", (error) => {
        console.log("Connection failed", error);
      });

      mqttClient.on("message", (topic, message) => {
        console.log(`Received message ${message} from topic ${topic}`);
        alertCode.value = message;
      });
    };

    let alertCode = ref(0);
    const updateAlertColor2 = (code) => {
      if (props.sensorData.alertSingle.active == false) code = 0;
      isActive.value = true;
      if (code == 3) {
        return { covClassAlertRed: isActive };
      } else if (code == 2) {
        return { covClassAlertRose: isActive };
      } else if (code == 1) {
        return { covClassAlertYelow: isActive };
      }

      return { covClassAlertGreen: isActive };
    };

    const doUnSubscribe = (topic) => {
      mqttClient.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
        }
      });
    };

    // const destroyConnection = () => {
    //   if (mqttClient.connected) {
    //     try {
    //       mqttClient.end();
    //     } catch (error) {
    //       console.log("Disconneced failed", error);
    //     }
    //   }
    // };

    const getColor = computed(() => {
      return props.sensorData.alertAck ? "red" : "green";
    });

    //////////////////

    //
    //     completedSteps: 1,
    // totalSteps: 6,
    // animateSpeed: 1000,
    // diameter: 300,
    // strokeWidth: 10,
    // innerStrokeWidth: 10,
    // strokeLinecap: "round",
    // startColor: "#00C58E",
    // stopColor: "#00E0A1",
    // innerStrokeColor: "#2F495E",
    // timingFunc: "linear",
    // isClockwise: true,

    let completedSteps = ref(43);
    let totalSteps = ref(100);
    let animateSpeed = ref(1000);
    let strokeWidth = ref(20);
    let innerStrokeWidth = ref(20);
    let strokeLinecap = ref("round");
    let startColor = ref("#00E0A1");
    let innerStrokeColor = ref("#2F495E");
    let timingFunc = ref("linear");
    let isClockwise = ref(true);

    let gradientProgress = ref({
      totalSteps,
      completedSteps,
      animateSpeed,
      strokeWidth,
      innerStrokeWidth,
      strokeLinecap,
      startColor,
      innerStrokeColor,
      timingFunc,
      isClockwise,
    });

    let gradProg = ref({
      completedSteps: 1,
      totalSteps: 6,
      animateSpeed: 1000,
      diameter: 300,
      strokeWidth: 10,
      innerStrokeWidth: 10,
      strokeLinecap: "round",
      startColor: "#00C58E",
      stopColor: "#00E0A1",
      innerStrokeColor: "#2F495E",
      timingFunc: "linear",
      isClockwise: true,
    });
    //

    return {
      show,
      sensorSeries,
      sensorsChartOptions,
      sensorsHistory,
      isActive,
      updateAlertColor,
      updateAlertColor2,
      updateBarClass,
      bar,
      dialog,
      alert_dialog,
      certificat_dialog,
      alert_ack_dialog,
      alert,
      setSensorAlert,
      setAlertData,
      updateSensorValue,
      nameCompute,
      unitCompute,
      now,
      timeAgo,
      getTimeAgo,
      doUnSubscribe,
      clientId,
      alertCode,
      roundVal,

      seriesCirc,
      chartOptionsCirc,
      options1,

      completedSteps,
      totalSteps,
      animateSpeed,
      strokeWidth,
      innerStrokeWidth,
      strokeLinecap,
      startColor,
      innerStrokeColor,
      timingFunc,
      isClockwise,
      gradProg,
      isSave,
      sensorlogo,
      getLogo,
      getBat,
      setAlertAck,
      getAlertAck,
      getColor,
      alertAck,
      alertNotification,
      sensorCharacteristic,
    };
  },
};
</script>

<style lang="scss">
@import "Sensor.scss";
</style>
