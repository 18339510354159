<template>
  <v-container fluid>
    <!-- <h3>{{ $t("charts") }}</h3> -->
    <h3>Charts</h3>
    <!-- <v-row>
      <v-col cols="6">
        <div>
          <v-date-picker v-model="range" color="green" :masks="masks" is-range>
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
                <v-icon class="w-4 h-4 mx-2"> mdi-arrow-right </v-icon>
                <input
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
              </div>
            </template>
          </v-date-picker>
        </div>
      </v-col>
      <div>
        {{ range }}
      </div>
    </v-row>

    <div>{{ dateRange }}</div> -->
    <v-card>
      <!-- {{ range }} -->
      <v-row>
        <v-card-title class="mt-10">
          <v-date-picker v-model="range" color="green" :masks="masks" is-range>
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
                <!-- <svg
              class="w-4 h-4 mx-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg> -->
                <v-icon class="w-4 h-4 mx-2"> mdi-arrow-right </v-icon>
                <input
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
              </div>
            </template>
          </v-date-picker>
        </v-card-title>
      </v-row>

      <v-row>
        <v-col cols="4">
          <!-- <v-card>
            <v-card-title>Selections des capteurs</v-card-title>
            <v-card-text>
              <v-list> </v-list>
            </v-card-text>
            <v-spacer></v-spacer>
          </v-card> -->
          <v-card class="mx-auto pa-2">
            <v-list select-strategy="multiple" lines="one">
              <v-list-group value="Sensors">
                <template v-slot:activator="{ props }">
                  <v-list-item
                    v-bind="props"
                    prepend-icon="mdi-select-group"
                    title="Groupe capteurs"
                  ></v-list-item>
                </template>

                <v-list-group
                  v-for="(sgroup, i) in tableDataListe"
                  :key="i"
                  :title="sgroup.name"
                  :value="sgroup.name"
                >
                  <template v-slot:activator="{ props }">
                    <v-list-item
                      v-bind="props"
                      :title="sgroup.name"
                    ></v-list-item>
                  </template>

                  <v-list-item
                    v-for="(sensor, i) in sgroup.sensorsIDs"
                    :key="i"
                    :title="sensor"
                    :value="sensor"
                    active-color="primary"
                    rounded="shaped"
                  >
                    <template v-slot:prepend="{ isActive }">
                      <v-list-item-action start>
                        <v-checkbox-btn
                          :model-value="isActive"
                          :title="sensor"
                          :value="sensor"
                          v-model="sensorsSelected"
                        ></v-checkbox-btn>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </v-list-group>
              </v-list-group>
            </v-list>

            <!-- {{ sensorsSelected }} -->
            <v-btn @click="getSensorsTimeSeries(sensorsSelected)"
              >Update charts</v-btn
            >
            {{ sensorslist_local }}
          </v-card>
        </v-col>
        <v-col cols="8">
          <!-- <div class="flex mb-2"> -->
          <v-btn
            class="ma-2"
            variant="text"
            icon="mdi-update"
            color="success"
            size="x-large"
            @click="refreshCharts"
          ></v-btn>
          <v-card class="mx-auto">
            <v-divider></v-divider>
            <apexchart
              type="line"
              :options="options"
              :series="series"
            ></apexchart>
            <!-- <div>
              {{ tableDataListe }}
            </div> -->
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
//https://vcalendar.io/datepicker.html
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Axios from "@/_services/caller.service";
import { forEach } from "lodash";

const url = process.env.VUE_APP_URL;

export default {
  name: "Charts_Name",
  setup(props) {
    const store = useStore();
    const dateRange = ref(new Date());
    let sensorslist = ref([]);
    const range = ref({
      start: new Date(),
      end: new Date(),
    });

    let rangeCopy = ref({
      start: new Date(),
      end: new Date(),
    });

    const masks = {
      input: "YYYY-MM-DD",
    };

    let series_new = ref([
      {
        name: "serie_1",
        data: [
          [1324508400000, 34],
          [1324594800000, 54],
          [1326236400000, 43],
        ],
      },
    ]);
    let series = ref([
      // {
      //   name: "serie_1",
      //   data: [
      //     [1324508400000, 34],
      //     [1324594800000, 54],
      //     [1326236400000, 43],
      //   ],
      // },
      // {
      //   data: [
      //     [1324508400000, 45],
      //     [1324594800000, 20],
      //     [1326236400000, 67],
      //   ],
      // },
    ]);

    // const options = {
    //   chart: {
    //     height: 350,
    //     type: "line",
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //     },
    //   },
    //   xaxis: {
    //     type: "datetime",
    //   },
    // };

    const options = ref({
      chart: {
        id: "Sensor chart",
      },
      title: {
        text: "Courbe des 10 dernieres mesures",
        align: "left",
      },
      tooltip: {
        x: {
          format: "HH:mm:ss",
        },
      },
      markers: {
        // pour les point sur la courbe
        size: 5,
      },
      dataLabels: {
        // Affiche la valeur du capteur sur la courbe
        //enabled: true,
      },
      stroke: {
        curve: "smooth",
        lineCap: "butt",
      },
      xaxis: {
        type: "datetime",
        format: "HH:mm:ss",
        labels: {
          datetimeUTC: false, //https://codepen.io/drpuur/pen/ExaQWGx
        },
        //tickAmount: 6,
      },
      // yaxis: {
      //   title: {
      //     text: props.sensorData.name,
      //   },
      //   //min: 0,
      //   //max: 70,
      // },
      // points: [
      //   {
      //     x: props.sensorData.timestamp * 1000,
      //     y: props.sensorData.value,
      //     marker: {
      //       size: 8,
      //       fillColor: "#fff",
      //       strokeColor: "red",
      //       radius: 2,
      //       cssClass: "apexcharts-custom-class",
      //     },
      //     label: {
      //       borderColor: "#FF4560",
      //       offsetY: 0,
      //       style: {
      //         color: "#fff",
      //         background: "#FF4560",
      //       },

      //       text: "Point Annotation",
      //     },
      //   },
      // ],
    });

    const timeout = ref("");
    const updateChart = () => {
      series.value.push({
        name: "serie_3",
        data: [
          [1324508400000, 69],
          [1324594800000, 100],
          [1326236400000, 99],
        ],
      });
    };

    const addDataChart = () => {
      series.value.push({
        name: "serie_2",
        data: [
          [1324508400000, 45],
          [1324594800000, 20],
          [1326236400000, 67],
        ],
      });
    };

    setTimeout(() => {
      timeout.value = "Temps est écoulé";
      //addDataChart();
    }, 5000);

    const getDateTime = (timestamp) => {
      return new Date(timestamp * 1000).toLocaleString(); //toString();
    };

    let sensorslist_local = ref([{ name: "", series: [] }]);
    const getSensorsTimeSeries = async (sensors) => {
      sensorslist_local.value.splice(0, sensorslist_local.value.length);
      const access_token = localStorage.getItem("jwt");

      sensors.forEach((elt, index) => {
        sensors[index] = elt.substr(0, elt.length - 2);
        sensorslist_local.value.push({ name: elt, series: [] });
      });
      await Axios.post("/api/v1/timeseries/timerange", {
        data: {
          sensorsgroup: sensors,
          startDate: range.value.start,
          endDate: range.value.end,
        },
      })
        .then((res) => {
          try {
            res.data.forEach((element) => {
              if (element.metadata["1"].value) {
                const item = [];
                //item[0] = parseInt(element.metadata.timestamp * 1000);
                item[1] = parseFloat(element.metadata["1"].value);
                item[0] = new Date(element.timestamp);
                //console.log(item[0]);
                sensorslist_local.value.forEach((sensor) => {
                  if (
                    sensor.name == element.metadata.mac + "_1" ||
                    sensor.name == element.metadata.mac + "_2" ||
                    sensor.name == element.metadata.mac + "_3" ||
                    sensor.name == element.metadata.mac + "_4" ||
                    sensor.name == element.metadata.mac + "_5" ||
                    sensor.name == element.metadata.mac + "_6" ||
                    sensor.name == element.metadata.mac + "_7" ||
                    sensor.name == element.metadata.mac + "_8" ||
                    sensor.name == element.metadata.mac + "_9" ||
                    sensor.name == element.metadata.mac + "_10"
                  ) {
                    sensor.series.push(item);
                  }
                });
              }
            });

            // series.value.push({
            //   name: sensorslist_local.value.name,
            //   data: sensorslist_local.value.series,
            // });

            series.value.splice(0, series.value.length);

            sensorslist_local.value.forEach((sensor) => {
              series.value.push({
                name: sensor.name,
                data: sensor.series,
              });
            });

            // const chart_new = {
            //   name: "serie_67",
            //   data: sensorslist.value,
            // };

            // const index = series.value.findIndex(
            //   (object) => object.name === value2.id
            // );

            // if (index === -1) {
            //   arr2.push(value2);
            // }
            // series.value.push();
          } catch (error) {
            sensorslist_local.value.forEach((sensor) => {
              series.value.push({
                name: sensor.name,
                data: sensor.series,
              });
            });
            console.log("ATTENTION!!!! " + error);
          }
        })
        .catch((error) => console.log(error));

      sensorsSelected.value.splice(0, sensorsSelected.value.length);
      sensorsSelected2.value = sensorsSelected.value;
      rangeCopy.value = range.value;
    };

    const getSensorsTimeSerie = async () => {
      const sensors = ["C47F518F7C5C"];
      //const sensorsgpchart = ref([]);
      //   AxiosUser.post("/api/v1/auth/login", {
      //   email: email,
      //   password: password,
      // })

      // await axios
      //   .get(url + "/api/v1/sensorstimeserie/timerange", {
      //     headers: {
      //       Authorization: `Bearer ${access_token}`,
      //     },
      //   })
      await Axios.post("/api/v1/timeseries/timerange", {
        data: {
          sensorsgroup: sensors,
          startDate: range.value.start,
          endDate: range.value.end,
        },
      })
        .then((res) => {
          try {
            res.data.forEach((element) => {
              if (element.metadata["1"].value) {
                const item = [];
                //item[0] = parseInt(element.metadata.timestamp * 1000);
                item[1] = parseFloat(element.metadata["1"].value);
                item[0] = new Date(element.timestamp);
                //console.log(item[0]);
                sensorslist.value.push(item);
              }
            });
            series.value.push({
              name: "serie_67",
              data: sensorslist.value,
            });
          } catch (error) {
            series.value.push({
              name: "serie_67",
              data: sensorslist.value,
            });
            console.log("ATTENTION!!!! " + error);
          }

          //getDateTime(element.timestamp);

          // series.value.push({
          //   name: "serie_3",
          //   data: [
          //     [1324508400000, 69],
          //     [1324594800000, 100],
          //     [1326236400000, 99],
          //   ],
          // });

          //console.log(sensorsgpchart.value);

          //console.log(series_new);
        })
        .catch((error) => console.log(error));
    };

    let sensorsSelected2 = ref([]);
    const refreshCharts = async () => {
      sensorslist_local.value.splice(0, sensorslist_local.value.length);

      sensorsSelected2.value.forEach((elt, index) => {
        sensorsSelected2[index] = elt.substr(0, elt.length - 2);
        sensorslist_local.value.push({ name: elt, series: [] });
      });
      await Axios.post("/api/v1/timeseries/timerange", {
        data: {
          sensorsgroup: sensorsSelected2,
          startDate: rangeCopy.value.start,
          endDate: rangeCopy.value.end,
        },
      })
        .then((res) => {
          try {
            res.data.forEach((element) => {
              if (element.metadata["1"].value) {
                const item = [];
                //item[0] = parseInt(element.metadata.timestamp * 1000);
                item[1] = parseFloat(element.metadata["1"].value);
                item[0] = new Date(element.timestamp);
                //console.log(item[0]);
                sensorslist_local.value.forEach((sensor) => {
                  if (
                    sensor.name == element.metadata.mac + "_1" ||
                    sensor.name == element.metadata.mac + "_2" ||
                    sensor.name == element.metadata.mac + "_3" ||
                    sensor.name == element.metadata.mac + "_4"
                  ) {
                    sensor.series.push(item);
                  }
                });
              }
            });

            // series.value.push({
            //   name: sensorslist_local.value.name,
            //   data: sensorslist_local.value.series,
            // });

            series.value.splice(0, series.value.length);

            sensorslist_local.value.forEach((sensor) => {
              series.value.push({
                name: sensor.name,
                data: sensor.series,
              });
            });

            // const chart_new = {
            //   name: "serie_67",
            //   data: sensorslist.value,
            // };

            // const index = series.value.findIndex(
            //   (object) => object.name === value2.id
            // );

            // if (index === -1) {
            //   arr2.push(value2);
            // }
            // series.value.push();
          } catch (error) {
            // sensorslist_local.value.forEach((sensor) => {
            //   series.value.push({
            //     name: sensor.name,
            //     data: sensor.series,
            //   });
            // });
            console.log("ATTENTION--!!!! " + error);
          }
        })
        .catch((error) => console.log(error));
    };

    let isGetAll = ref(false);
    const getSensorsGroup = () => {
      console.log("getSensorsGroup");
      isGetAll.value = false;
      store.dispatch("sensorsStore/getAllSensorsGroup");
      store.state.sensorsStore.sensorsGroup;
    };

    const tableDataListe = computed(
      () => store.state.sensorsStore.sensorsGroup
    );

    onMounted(() => {
      console.log("Charts monted");
      getSensorsGroup();
    });

    const admins = [
      ["Management", "mdi-account-multiple-outline"],
      ["Settings", "mdi-cog-outline"],
    ];

    const sensorsSelected = ref([]);

    return {
      dateRange,
      series,
      series_new,
      options,
      timeout,
      updateChart,
      getSensorsTimeSerie,
      getSensorsTimeSeries,
      refreshCharts,
      sensorslist_local,
      sensorslist,

      range,
      masks,

      getSensorsGroup,
      tableDataListe,
      admins,
      sensorsSelected,
    };
  },
};
</script>
<style></style>
