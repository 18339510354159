<template>
  <div class="d-flex flex-row-reverse mb-6">
    <v-btn
      class="ma-2 pa-2"
      color="success"
      variant="flat"
      @click="isGetAll = true"
    >
      Create
    </v-btn>
    <v-btn class="ma-2 pa-2" color="success" variant="flat" @click="getAll">
      &nbsp;&nbsp;&nbsp; List &nbsp;&nbsp;&nbsp;
    </v-btn>
  </div>

  <!-- <div>
      <div class="d-flex justify-end mb-6">
        <v-btn color="success" class="ma-2 pa-2" @click="isGetAll = true">
          Create group</v-btn
        >
        <v-btn color="success" class="ma-2 pa-2" @click="getAll"
          >All groups</v-btn
        >
      </div>
    </div> -->
  <v-container fluid>
    <v-form class="multi-col-validation mx-4" ref="form" v-if="isGetAll">
      <h3>Create users group</h3>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="formData.name"
            label="Groupe Name"
            variant="outlined"
            dense
            placeholder="Groupe Name"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="overflow-y-auto">
            <v-banner class="justify-center text-h5 font-weight-light" sticky>
              Users can add
            </v-banner>
            <v-card-text>
              {{ usersList }}
              <ul>
                <li v-for="(item, i) in usersList" :key="i">
                  <span @click="addToList(usersList, item)"> {{ item }}</span>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="overflow-y-auto">
            <v-banner class="justify-left text-h5 font-weight-light" sticky>
              Users in group
            </v-banner>
            <v-card-text>
              <ul>
                <li v-for="(sensorId, i) in listToBeCreadted" :key="i">
                  <span @click="removeFromList(listToBeCreadted, sensorId)">
                    {{ sensorId }}
                  </span>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="success" @click="submit"> Submit </v-btn>
          <v-btn color="error" type="reset" outlined class="mx-2">
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <div>
    <!-- <v-row v-if="isGetAlerts == false"> </v-row> -->
    <h3 v-if="isGetAll == false">Users group</h3>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import Axios from "axios";

export default {
  setup() {
    let formData = ref({
      name: "",
      org: localStorage.getItem("organization"),
    });
    const organizationName = ref("");
    const city = ref("");
    const country = ref("");
    const company = ref("");
    const email = ref("");
    const checkbox = ref(false);
    const form = ref(null);

    const store = useStore();

    const reset = () => {
      form.value.reset();
    };

    const submit = () => {
      store.dispatch("sensorsStore/createUsersGroup", formData.value);
    };

    let isGetAll = ref(false);
    const getAll = () => {
      isGetAll.value = false;
      store.dispatch("sensorsStore/getAllUsersGroup");
    };

    const fetchUsersGroup = async () => {
      isGetAll.value = false;
      const access_token = localStorage.getItem("jwt");
      // await Axios.get(url + "/api/users", {
      await Axios.get("/api/v1/users")
        .then((res) => {
          //tableData.value = res.data;
        })
        .catch((error) => console.log(error));
    };

    let listToBeCreadted = ref([]);
    const addToList = (arr, sensorId) => {
      console.log(usersList);
      var index = arr.indexOf(sensorId);
      //console.log(index);
      if (index > -1) {
        listToBeCreadted.value.push(sensorId);
        //listToBeCreadted.value = listToBeCreadted.value.filter(onlyUnique);
        listToBeCreadted.value = [
          ...new Set(listToBeCreadted.value.filter(onlyUnique)), //https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
        ];
        arr.splice(index, 1);
        //arr.splice(index, 1);
      }
    };

    const removeFromList = (arr, sensorId) => {
      var index = arr.indexOf(sensorId);
      if (index > -1) {
        arr.splice(index, 1);
      }
    };

    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    //let usersList = [];
    let sensorsToBeList = ref([]);

    store.dispatch(
      "sessionStore/getAllUsersOfOrg",
      localStorage.getItem("organization")
    );

    //return store.getters.sensorsInfo;
    //const access_token = localStorage.getItem("jwt");
    // await Axios.get(url + "/api/users", {
    // Axios
    //   .get(process.env.VUE_APP_URL + "/api/v1/users", {
    //     headers: {
    //       Authorization: `Bearer ${access_token}`,
    //     },
    //   })
    //   .then((res) => {
    //     //tableData.value = res.data;
    //   })
    //   .catch((error) => console.log(error));

    // let data = [];
    // store.getters.sessionStore.forEach((element) => {
    //   data.push(element.id);
    //   //sensorsToBeList.value.push(element.id);
    // });
    //return data;
    //   console.log("==========> " + store.state.sensorsStore.usersOfOrg);
    //   return store.state.sensorsStore.usersOfOrg;
    // });

    const usersList = computed(() => store.state.sensorsStore.usersOfOrg);

    return {
      reset,
      submit,
      organizationName,
      city,
      country,
      company,
      email,
      checkbox,
      isGetAll,
      formData,
      getAll,
      addToList,
      removeFromList,
      usersList,
      sensorsToBeList,
      listToBeCreadted,
    };
  },
};
</script>
