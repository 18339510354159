<template>
  <v-container>
    <v-card>
      <!-- <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1"> -->
      <v-card-title>
        <span class="me-3 text-h5">{{ name }}</span>
        <v-spacer></v-spacer>
        <!-- <span class="text-xs text--disabled cursor-pointer">View All</span> -->
      </v-card-title>
      <v-card-text>
        <!-- {{ infos }} -->
        <div>
          <v-row>
            <v-col class="pa-2 ma-2">
              <v-dialog v-model="dialog" persistent width="auto">
                <template v-slot:activator="{ props }">
                  <v-btn color="green" v-bind="props"> Update </v-btn>
                  <!-- <v-btn @click="cmdUpdate">  </v-btn> -->
                </template>
                <v-card>
                  <v-card-title class="text-h5"> Confirmation? </v-card-title>
                  <v-card-text>Confirm Firmware Update Action</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green-darken-1"
                      variant="text"
                      @click="dialog = false"
                    >
                      No
                    </v-btn>
                    <v-btn
                      color="green-darken-1"
                      variant="text"
                      @click="cmdUpdate"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col>
              <!-- <v-sheet class="pa-2 ma-2"> .v-col-auto </v-sheet> -->
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <!-- </div>
    </div> -->
    </v-card>
  </v-container>
</template>

<script>
import { ref } from "@vue/reactivity";
import http from "../../http-commun";
export default {
  name: "UpdateDevice",
  props: ["infos"],

  setup(props) {
    const name = "Firmware Upadate";
    let dialog = ref(false);

    const cmdUpdate = () => {
      dialog.value = false;
      console.log("Request Update Firmware");
      const access_token = localStorage.getItem("jwt");
      const firmwareVersion = "firmware_v1.0";
      http
        .post(
          "api/v1/cachefota/" + props.infos.mac,
          { version: firmwareVersion },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return {
      name,
      dialog,
      cmdUpdate,
    };
  },
};
</script>

<style></style>
