<template>
  <v-container>
    <v-card>
      <!-- <div class="d-flex flex-sm-row flex-column">
      <div class="flex-grow-1"> -->
      <v-card-title>
        <span class="me-3 text-h5">{{ name }}</span>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <!-- <v-row>
        <v-col
          v-for="(stream, i) in streams"
          :key="i"
          cols="12"
          class="text-xs text--disabled cursor-pointer"
          >{{ stream.name }}</v-col
        >
      </v-row> -->
        <div
          v-for="(stream, i) in streams"
          :key="i"
          cols="12"
          class="text-xs text--disabled cursor-pointer"
        >
          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div>
              <h4 class="font-weight-medium">{{ stream.name }}</h4>
            </div>
            <v-spacer></v-spacer>
            <div>
              <h4 class="font-weight-medium">{{ stream.coef_A }}</h4>
            </div>
            <v-spacer></v-spacer>
            <div>
              <h4 class="font-weight-medium">{{ stream.coef_B }}</h4>
            </div>
            <v-spacer></v-spacer>
            <div>
              <h4 class="font-weight-medium">{{ stream.coef_C }}</h4>
            </div>
            <v-spacer></v-spacer>
            <div>
              <h4 class="font-weight-medium">{{ stream.coef_D }}</h4>
            </div>
            <v-spacer></v-spacer>
            <div>
              <h4 class="font-weight-medium">{{ stream.real_val }}</h4>
            </div>
            <v-spacer></v-spacer>
            <div>
              <h4 class="font-weight-medium">
                {{
                  poly(
                    stream.coef_A,
                    stream.coef_B,
                    stream.coef_C,
                    stream.coef_D,
                    stream.real_val
                  )
                }}
              </h4>
            </div>
            <v-spacer></v-spacer>
            <div>
              <h4>Ajuster</h4>
            </div>
          </div>
        </div>
      </v-card-text>
      <!-- </div>
    </div> -->
    </v-card>
  </v-container>
</template>

<script>
export default {
  setup() {
    //const name = "Streams";
    const name = "Adjustment Of Values";
    const streams = [
      {
        name: "Stream_1",
        coef_A: 0,
        coef_B: 0,
        coef_C: 1,
        coef_D: 0,
        real_val: 5,
      },
      {
        name: "Stream_2",
        coef_A: 0,
        coef_B: 0,
        coef_C: 1,
        coef_D: 0,
        real_val: 5,
      },
      {
        name: "Stream_3",
        coef_A: 0,
        coef_B: 0,
        coef_C: 1,
        coef_D: 0,
        real_val: 5,
      },
      {
        name: "Stream_4",
        coef_A: 0,
        coef_B: 0,
        coef_C: 1,
        coef_D: 0,
        real_val: 5,
      },
      {
        name: "Stream_5",
        coef_A: 0,
        coef_B: 0,
        coef_C: 1,
        coef_D: 0,
        real_val: 5,
      },
      {
        name: "Stream_6",
        coef_A: 0,
        coef_B: 0,
        coef_C: 1,
        coef_D: 0,
        real_val: 5,
      },
    ];

    const poly = (a, b, c, d, x) => {
      return a * x * x * x + b * x * x + c * x + d;
    };
    return {
      name,
      streams,
      poly,
    };
  },
};
</script>

<style></style>
