<template>
  <div class="d-flex flex-row-reverse mb-6">
    <v-dialog v-model="dialogAddOrg" persistent>
      <template v-slot:activator="{ props }">
        <v-btn class="ma-2 pa-2" color="success" variant="flat" v-bind="props">
          Create
        </v-btn>
      </template>
      <v-card max-width="700px">
        <v-card-title>
          <span class="text-h5">Add org</span>
        </v-card-title>
        <v-card-text>
          <!-- <v-container> -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formData.name"
                label="Name"
                outlined
                dense
                placeholder="Name"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="formData.description"
                filled
                auto-grow
                label="Four rows"
                rows="4"
                row-height="30"
                shaped
                required
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-btn color="primary" @click="submit"> Submit </v-btn>
              <v-btn type="reset" outlined class="mx-2"> Reset </v-btn>
            </v-col>
            <v-alert type="success" variant="outlined">
              Delete Succes!
            </v-alert>
          </v-row>
          <!-- </v-container> -->
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" text @click="dialogAddOrg = false">
            Close
          </v-btn>
          <!-- <v-btn color="blue-darken-1" text @click="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn class="ma-2 pa-2" color="success" variant="flat" @click="fetchOrgs">
      &nbsp;&nbsp;&nbsp; List &nbsp;&nbsp;&nbsp;
    </v-btn>
  </div>
  <h3>{{ $t("organizations") }}</h3>
  <!-- {{ organizations }} -->

  <v-data-table
    :headers="headers"
    :items="organizations"
    :items-per-page="10"
    :loading="loading"
    loading-text="Loading... Please wait"
    item-value="name"
    show-select
    show-expand
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Organizations</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ props }">
            <v-btn dark class="mb-2" v-bind="props"> New Item </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle() }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Org name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.description"
                      label="Description"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue-darken-1" variant="text" @click="close">
                Cancel
              </v-btn>
              <v-btn color="blue-darken-1" variant="text" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this organization?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green-darken-1" variant="text" @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="green-darken-1"
                variant="text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon size="small" class="me-2" @click="editItem(item.raw)">
        mdi-pencil
      </v-icon>
      <v-icon size="small" @click="deleteItem(item.raw)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn color="primary" @click="initialize"> Reset </v-btn> -->
      <v-progress-circular
        :size="70"
        :width="7"
        color="success"
        indeterminate
      ></v-progress-circular>
    </template>
  </v-data-table>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "@vue/runtime-core";
export default {
  setup(props) {
    const store = useStore();
    const loading = ref(true);

    let formData = ref({
      name: "",
      description: "",
    });

    const submit = async () => {
      //form.value.resetValidation();
      await store.dispatch(
        "organizationStore/createOrganization",
        formData.value
      );
    };

    // Gestion de la liste des organisations
    let dialogAddOrg = ref(false);

    const fetchOrgs = async () => {
      loading.value = true;
      await store.dispatch("organizationStore/getAllOrgs");
      organizations.value = store.state.organizationStore.organizations;
      loading.value = false;
    };

    onMounted(() => {
      fetchOrgs();
    });

    let organizations = ref([]);
    // organizations = computed(() => {
    //   return store.state.organizationStore.organizations;
    // });

    let dialog = ref(false);
    //let editedIndex = ref([]);
    let editedItem = ref({
      name: "organ ",
      description: "Descrip",
    });

    const editItem = (item) => {
      console.log("Item" + item.name);
      // editedItem.value = item;
      // dialog.value = true;
      // = this.organizations.indexOf(item);
      editedItem.value = Object.assign({}, item);
      dialog.value = true;
    };

    let itemIdTobeDelelete = 0;
    const deleteItem = async (item) => {
      dialogDelete.value = true;
      itemIdTobeDelelete = item._id;
    };

    const deleteItemConfirm = async () => {
      closeDelete();
      console.log("Item " + itemIdTobeDelelete);
      await store.dispatch("organizationStore/deleteById", itemIdTobeDelelete);
      itemIdTobeDelelete = 0;
      fetchOrgs();
    };

    const updateItem = async (item) => {
      dialog.value = false;
      console.log(item._id);
      await store.dispatch("organizationStore/updateById", item);
    };

    const initialize = () => {};

    let editedIndex = ref(-1);
    const formTitle = () => {
      return editedIndex.value === -1 ? "New Item" : "Edit Item";
    };

    const close = () => {
      dialog.value = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    };

    const save = () => {
      updateItem(editedItem.value);
      fetchOrgs();
      close();
    };

    const closeDelete = () => {
      dialogDelete.value = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    };

    const dialogDelete = ref(false);

    const headers_old = [
      // { text: "ID", sortable: false, value: "id" },
      { text: "NAME", value: "full_name" },
      { text: "DESCRIPTION", value: "description" },
      { text: "ACTIONS", value: "action" },
    ];
    const headers = [
      //{ title: "ID", sortable: false, key: "_id" },
      { title: "NAME", key: "name" },
      { title: "DESCRIPTION", key: "description" },
      { title: "CREATED AT", key: "createdAt" },
      { title: "ACTIONS", key: "actions" },
    ];

    return {
      fetchOrgs,
      updateItem,
      deleteItem,
      deleteItemConfirm,
      editItem,
      initialize,
      headers,
      organizations,
      dialogAddOrg,
      dialog,
      editedIndex,
      editedItem,
      formTitle,
      dialogDelete,
      closeDelete,
      close,
      save,
      formData,
      submit,
      loading,
    };
  },
};
</script>

<style></style>
