<template>
  <!-- <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="500px">
    <v-btn color="primary" dark slot="activator">Open Dialog</v-btn>
    <v-card>
      <v-card-title>
        <span class="headline">User Profile</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <v-text-field label="Legal first name" required></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Legal middle name"
                hint="example of helper text only on focus"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field
                label="Legal last name"
                hint="example of persistent helper text"
                persistent-hint
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field label="Email" required></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                label="Password"
                type="password"
                required
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                label="Age"
                required
                :items="['0-17', '18-29', '30-54', '54+']"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                label="Interests"
                multiple
                autocomplete
                chips
                :items="[
                  'Skiing',
                  'Ice hockey',
                  'Soccer',
                  'Basketball',
                  'Hockey',
                  'Reading',
                  'Writing',
                  'Coding',
                  'Basejump',
                ]"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" flat @click.native="dialog = false"
          >Close</v-btn
        >
        <v-btn color="blue darken-1" flat @click.native="dialog = false"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
    </v-dialog>
  </v-layout> -->

  <v-container fluid>
    <v-card>
      <v-card-title>
        <span class="text-h5">Create user --</span>
      </v-card-title>
      <v-card-text>
        <!-- <v-container> -->
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="First name*"
              v-model="firstName"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Last name" v-model="lastName"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Username*"
              v-model="username"
              persistent-hint
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Email*"
              v-model="email"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field label="Phone" v-model="phone" required></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Password*"
              type="password"
              v-model="password"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="rolesList"
              v-model="roles"
              label="Roles*"
              item-title="name"
              item-value="_id"
              multiple
              required
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="orgsList"
              v-model="orgs"
              label="Organizations"
              item-title="name"
              item-value="id"
              multiple
              required
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- </v-container> -->
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" text @click="dialogAddUser = false">
          Close
        </v-btn>
        <v-btn color="blue-darken-1" text @click="createUser"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
