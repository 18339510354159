<!-- <template>
  <div>
    <h1>Device Firmware</h1>
    <v-card>
      <v-card-title> Update node </v-card-title>

      <v-card-text>
        <v-file-input accept="image/*" label="File input"></v-file-input>
        <v-file-input
          label="File input"
          variant="filled"
          prepend-icon="mdi-camera"
        ></v-file-input>
      </v-card-text>
      <v-card-action>
        <v-btn @click="save()"> enregistrer </v-btn>
      </v-card-action>
    </v-card>
  </div>
</template> -->

<template>
  <div>
    <div v-if="currentFile">
      <div>
        <v-progress-linear
          v-model="progress"
          color="light-blue"
          height="25"
          reactive
        >
          <strong>{{ Math.ceil(progress) }}%</strong>
        </v-progress-linear>
      </div>
    </div>

    <v-card>
      <v-row no-gutters justify="center">
        <!-- <v-col cols="8">
        <v-file-input
          show-size
          label="File input"
          @change="selectFile"
        ></v-file-input>
      </v-col> -->
        <v-col cols="6" class="pl-2">
          <v-file-input
            show-size
            label="File input"
            :rules="rules"
            accept="application/macbinary"
            placeholder="Pick an avatar"
            prepend-icon="mdi-camera"
            @change="selectFile"
            clearable
            variant="solo"
          ></v-file-input>
        </v-col>
      </v-row>

      <v-row no-gutters justify="center">
        <v-col cols="6" class="pl-2">
          <v-btn
            :loading="loading[2]"
            :disabled="loading[2]"
            color="success"
            prepend-icon="mdi-cloud-upload"
            @click="upload"
            size="x-large"
          >
            Charger
          </v-btn>
          <!-- <v-btn color="success" dark small @click="upload">
          Upload
          <v-icon right dark>mdi-cloud-upload</v-icon>
        </v-btn> -->
        </v-col>
      </v-row>
    </v-card>

    <v-alert v-if="message" border="left" color="blue-grey" dark>
      {{ message }}
    </v-alert>

    <v-card v-if="fileInfos.length > 0" class="mx-auto">
      <v-list>
        <h2>List of Files</h2>
        <!-- <v-list-item-group color="primary"> -->
        <v-list-item v-for="(file, index) in fileInfos" :key="index">
          <a :href="file.url">{{ file.name }}</a>
        </v-list-item>
        <!-- </v-list-item-group> -->
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { ref } from "vue";
import http from "../../http-commun";
import UploadService from "../../services/UploadFilesService";
export default {
  setup(props) {
    const rules = [
      (value) => {
        return (
          !value ||
          !value.length ||
          value[0].size < 2000000 ||
          "Avatar size should be less than 2 MB!"
        );
      },
    ];

    const save = () => {
      const access_token = localStorage.getItem("jwt");
      http
        .post(
          "api/v1/firmware",
          {
            name: "test",
          },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const currentFile = ref();
    let progress = ref(0);
    const message = ref("");
    const fileInfos = ref([]);

    const selectFile = (file) => {
      console.log(file);
      progress.value = 0;
      currentFile.value = file.target.files[0];
    };

    const prog = (ev) => {
      progress.value = Math.round(100 * ev.loaded);
      console.log(progress.value);
    };
    const upload = (event) => {
      console.log(event);
      load(2);
      if (!currentFile.value) {
        message.value = "Please select a file!";
        return;
      }

      message.value = "";
      const formData = new FormData();
      formData.append("file", currentFile.value, currentFile.value.name);

      const access_token = localStorage.getItem("jwt");
      http
        .post("api/v1/firmware", formData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          prog,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });

      /*
      UploadService.upload(formData, (event) => {
        progress.value = Math.round((100 * event.loaded) / event.total);
      })
        .then((response) => {
          message.value = response.data.message;
          return UploadService.getFiles();
        })
        .then((files) => {
          fileInfos.value = files.data;
        })
        .catch(() => {
          progress.value = 0;
          message.value = "Could not upload the file!";
          currentFile.value = undefined;
        });
        */
    };
    let loading = ref([]);
    const load = (i) => {
      loading.value[i] = true;
      setTimeout(() => (loading.value[i] = false), 3000);
    };

    return {
      save,
      selectFile,
      upload,
      loading,
      load,
      rules,
      currentFile,
      progress,
      message,
      fileInfos,
    };
  },
};
</script>

<style lang="scss" scoped></style>
