<template>
  <v-container fluid>
    <div>
      <h3>Sensors Group - Users Group - Alerts - Notifications</h3>
      <v-card>
        <v-tabs v-model="tab">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-window v-model="tab">
          <!-- <v-window-item>
            <create-sensors-users />
          </v-window-item> -->
          <v-window-item> <users-group-list></users-group-list> </v-window-item>
          <v-window-item>
            <create-sensors-group />
          </v-window-item>
          <v-window-item>
            <create-sensors-alert />
          </v-window-item>
          <v-window-item>
            <alert-condition-list />
          </v-window-item>
          <!-- <v-window-item>
            <create-sensors-notifications />
          </v-window-item> -->
          <v-window-item>
            <notification-list />
          </v-window-item>
        </v-window>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import CreateSensorsAlert from "./CreateSensorsAlert.vue";
import CreateSensorsGroup from "./CreateSensorsGroup.vue";
import CreateSensorsUsers from "./CreateSensorsUsers.vue";
import CreateSensorsNotifications from "./CreateSensorsNotifications.vue";
import CreateAlertCondition from "./CreateAlertCondition.vue";
import AlertConditionList from "./AlertConditionList.vue";
import { ref } from "vue";
import UsersGroupList from "./UsersGroupList.vue";
import NotificationList from "./NotificationList.vue";
export default {
  components: {
    CreateSensorsAlert,
    CreateAlertCondition,
    CreateSensorsGroup,
    CreateSensorsUsers,
    CreateSensorsNotifications,
    AlertConditionList,
    UsersGroupList,
    NotificationList,
  },

  setup() {
    const tab = ref("");
    // tabs
    const tabs = [
      { title: "Users Group", icon: "mdi-lock-open-outline" },
      { title: "Sensors Group", icon: "mdi-account-outline" },
      { title: "Alerts Criteria", icon: "mdi-information-outline" },
      { title: "Alerts Rules", icon: "mdi-information-outline" },
      // { title: "Notifications Rules", icon: "mdi-bullhorn-variant-outline" },
      { title: "Notifications Rules", icon: "mdi-bullhorn-variant-outline" },
    ];

    return {
      tab,
      tabs,
    };
  },
};
</script>

<style></style>
